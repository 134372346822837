<template>
  <section class="full-height">

    <h1>preview {{ pageTitle }} </h1>

    <div class="columns is-gapless is-marginless is-multiline m1rem">
      <SearchInput v-model="filterSearch" :label="'Search'" @change="updateSearch" @enter="updateFilters" />
      <MultiSelect v-model="filterRoles.selectValues" :label="getRolesFilterLabel()" @change="updateSelectedRoles" />
      <MultiSelect v-model="filterUseDepartments.selectValues" :label="getUserDepartmentsFilterLabel()" @change="updateSelectedUserDepartments" />
      <MultiSelect v-model="filterLocations.selectValues" :label="getLocationFilterLabel()" @change="updateSelectedLocations" />
      <MultiSelect v-model="filterSchedules.selectValues" :label="getScheduleFilterLabel()" @change="updateSelectedSchedules" />
      <MultiSelect v-model="filterCodeSchedules.selectValues" :label="getCodeScheduleFilterLabel()" @change="updateSelectedCodeSchedules" />
      <MultiSelect v-model="filterCodeSets.selectValues" :label="getCodeSetsFilterLabel()" @change="updateSelectedCodeSets" />
      <MultiSelect v-model="filterCodeTypes.selectValues" :label="getCodeTypeFilterLabel()" @change="updateSelectedCodeTypes" />
      <MultiSlider v-model="filterQuotum.value" :label="getQuotumFilterLabel()" :min="filterQuotum.min"
        :max="filterQuotum.max" :step="1" :ticks="true"
        @change="updateFilterQuotum" />
      <MultiSlider v-model="filterPerformance.value" :label="getPerformanceFilterLabel()" :min="filterPerformance.min"
        :max="filterPerformance.max" :step="0.05" :ticks="true"
        @change="updateFilterPerformance" />
      <MultiSlider v-model="filterCoverage.value" :label="getCoverageFilterLabel()" :min="filterCoverage.min"
        :max="filterCoverage.max" :step="0.05" :ticks="true"
        @change="updateFilterCoverage" />
      <MultiSlider v-if="showAvailability" v-model="filterAvailable.value" :label="getAvailableFilterLabel()" :min="filterAvailable.min"
        :max="filterAvailable.max" :step="1" :ticks="true"
        @change="updateFilterAvailable" />
      <MultiSlider v-model="filterAssigned.value" :label="getAssignedFilterLabel()" :min="filterAssigned.min"
        :max="filterAssigned.max" :step="1" :ticks="true"
        @change="updateFilterAssigned" />
      <MultiSlider v-model="filterAssignedPhase.value" :label="getAssignedPhaseFilterLabel()" :min="filterAssignedPhase.min"
        :max="filterAssignedPhase.max" :step="1" :ticks="true"
        @change="updateFilterAssignedPhase" />
      <MultiSlider v-model="filterHolidays.value" :label="getHolidaysFilterLabel()" :min="filterHolidays.min"
        :max="filterHolidays.max" :step="1" :ticks="true"
        @change="updateFilterHolidays" />

      <b-field>
          <b-switch size="is-small" v-model="showAvailability" @input="updateFilters" >show availability</b-switch>
          <b-switch v-if="showAvailability" size="is-small" v-model="openAvailableOnly" @input="updateFilters" >open allowed Availability only</b-switch>
          <b-switch size="is-small" v-model="showSteaksOnly" @input="updateFilters" >StreaksOnly</b-switch>
          <b-switch size="is-small" v-model="hideLowMaxforStreaks" @input="updateFilters" >hideLowMaxForStreaks</b-switch>
          <b-switch size="is-small" v-model="locationMisMatch" @input="updateFilters" >LocationMisMatch</b-switch>
          <b-switch v-if="hasLinkedShifts" size="is-small" v-model="showLinkedShiftsOnly" @input="updateFilters" >linkedShiftsOnly</b-switch>
          <b-switch size="is-small" v-model="holidaysOnly" @input="updateFilters" >HolidaysOnly</b-switch>
          <b-switch size="is-small" v-model="atwOnly" @input="updateFilters" >atwOnly</b-switch>
          <b-switch size="is-small" v-model="showBlocked" @input="updateFilters" >blockedDays</b-switch>
          <b-switch size="is-small" v-model="showSlots" @input="updateFilters" >slots</b-switch>
          <b-switch size="is-small" v-model="errorMisMatch" @input="updateFilters" >Errors</b-switch>
          <b-switch size="is-small" v-model="hoverBoxAutoCloseDisabled" @update="hideDetails">detail stay open</b-switch>
          <b-switch size="is-small" v-model="enableAgenda" @input="updateFilters">agenda</b-switch>
          <b-switch size="is-small" v-model="showTables">tables</b-switch>
      </b-field>

      <b-field>
          <b-select placeholder="sort by" v-model="sortOptionSelected">
              <option
                  v-for="option in sortOptions"
                  :value="option"
                  :key="option">
                  {{ option }}
              </option>
          </b-select>
      </b-field>

      <div class="column">
        <b-button class="button" @click="update">update</b-button>
      </div>
    </div>


    <div ref="UserOverviewWrap" id="UserOverviewWrap" class="">
    <!-- <div ref="AvailabilityOverviewWrap" id="AvailabilityOverviewWrap" class=""> -->

    </div>


    <div v-if="showTables && tableUser" class="columns is-multiline" style="padding-left: 50px; padding-right: 50px; padding-top: 50px">
      <h1>userId:{{tableUser.user_id}} companyUserId:{{tableUser.company_user_id}} {{tableUser.email}}</h1>
    </div>


    <div v-if="showTables && tableUser" class="columns is-multiline" style="padding-left: 50px; padding-right: 50px">
        <h1>Code Overview:</h1>
        <b-switch size="is-small" v-model="showFilteredCodeOverview">show Filtered Assigned</b-switch>
        <b-table
            v-if="showFilteredCodeOverview"
            class="column is-12"
            :data="tableUserCodeOverview"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :paginated="true"
            :isPaginationSimple="false"
            perPage="100"
            >

            <b-table-column field="schedule_id" label="schedule id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.schedule_id }}
            </b-table-column>
            <b-table-column field="code_schedule_id" label="code schedule id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.code_schedule_id }}
            </b-table-column>
            <b-table-column field="code_id" label="code id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.code_id }}
            </b-table-column>
            <b-table-column field="code" label="code" sortable numeric v-slot="props" centered searchable>
                {{ props.row.code }}
            </b-table-column>
            <b-table-column field="name" label="name" sortable numeric v-slot="props" centered searchable>
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="start" label="times" sortable numeric v-slot="props" centered searchable>
                {{ props.row.start.substr(0,5) }}-{{ props.row.end.substr(0,5) }}
            </b-table-column>
            <b-table-column field="codeSets" label="codeSets" sortable numeric v-slot="props" centered searchable>
                {{ props.row.codeSets }}
            </b-table-column>

            <b-table-column field="count" label="shifts" sortable numeric v-slot="props" centered searchable>
                {{ props.row.count }}
            </b-table-column>
            <b-table-column field="availabe" label="availabe" sortable v-slot="props" centered searchable>
                {{ props.row.availabe }}
            </b-table-column>
            <b-table-column field="assigned" label="assigned" sortable numeric v-slot="props" centered searchable>
                {{ props.row.assigned }}
            </b-table-column>




            <template #empty>
                <div class="has-text-centered">No filtered data</div>
            </template>

        </b-table>
    </div>


    <div v-if="showTables && tableUser" class="columns is-multiline" style="padding: 50px">
        <h1>assigned: ({{tableUserAssigned.length}})</h1>
        <b-switch size="is-small" v-model="showFilteredAssignedTable">show Filtered Assigned</b-switch>
        <b-table
            v-if="showFilteredAssignedTable"
            class="column is-12"
            :data="tableUserAssigned"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :paginated="true"
            :isPaginationSimple="false"
            perPage="100"
            >

            <b-table-column field="shift_id" label="shift id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.shiftId }}
            </b-table-column>
            <b-table-column field="vacancyId" label="vacancy id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.vacancyId }}
            </b-table-column>
            <b-table-column field="codeSetListNames" label="codeSets" sortable v-slot="props" centered searchable>
                {{ props.row.codeSetListNames }}
            </b-table-column>
            <b-table-column field="scheduleId" label="schedule id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.scheduleId }}
            </b-table-column>
            <b-table-column field="codeScheduleId" label="code Schedule id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.codeScheduleId }}
            </b-table-column>
            <b-table-column field="codeId" label="code id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.codeId }}
            </b-table-column>
            <b-table-column field="codeCode" label="code" sortable numeric v-slot="props" centered searchable>
                {{ props.row.codeCode }}
            </b-table-column>
            <b-table-column field="codeName" label="code name" v-slot="props" centered searchable>
                {{ props.row.codeName }}
            </b-table-column>
            <b-table-column field="dateStr" label="date" sortable date v-slot="props" centered searchable>
                {{ props.row.dateStr }}
            </b-table-column>
            <!-- <b-table-column field="startTime" label="startTime" sortable v-slot="props" centered searchable>
                {{ props.row.startTime }}
            </b-table-column> -->
            <b-table-column field="endTime" label="endTime" sortable v-slot="props" centered searchable>
                {{ props.row.endTime }}
            </b-table-column>
            <b-table-column field="weekNr" label="weekNr" sortable v-slot="props" centered searchable>
                {{ props.row.weekNr }}
            </b-table-column>
            <b-table-column field="monthNr" label="monthNr" sortable v-slot="props" centered searchable>
                {{ props.row.monthNr }}
            </b-table-column>
            <b-table-column field="duration" label="duration" sortable numeric v-slot="props" centered searchable>
                {{ props.row.duration }}
            </b-table-column>
            <b-table-column field="minMaxHoursShift" label="minmax" sortable numeric v-slot="props" centered searchable>
                {{ props.row.minMaxHoursShift }}
            </b-table-column>
            <b-table-column field="quotemValue" label="quotum" sortable numeric v-slot="props" centered searchable>
                {{ props.row.quotemValue }}
            </b-table-column>
            <b-table-column field="type" label="type" sortable v-slot="props" centered searchable>
                {{ props.row.type }}
            </b-table-column>
            <b-table-column field="isHoliday" label="holiday" sortable v-slot="props" centered searchable>
                {{ props.row.isHoliday }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No filtered availability</div>
            </template>

        </b-table>
    </div>


    <div v-if="showTables && tableUser" class="columns is-multiline" style="padding: 50px">
        <h1>availability: ({{tableUserAvailable.length}})</h1>
        <b-switch size="is-small" v-model="showFilteredAvailabilityTable">show Filtered Availability</b-switch>
        <b-table
            v-if="showFilteredAvailabilityTable"
            class="column is-12"
            :data="tableUserAvailable"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :paginated="true"
            :isPaginationSimple="false"
            perPage="100"
            >

            <b-table-column field="shift_id" label="shift id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.shiftId }}
            </b-table-column>
            <b-table-column field="vacancyId" label="vacancy id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.vacancyId }}
            </b-table-column>
            <b-table-column field="is_generated" label="generated" sortable numeric v-slot="props" centered searchable>
                {{ props.row.is_generated }}
            </b-table-column>
            <b-table-column field="exempt_from_streak" label="exempt from streak" sortable numeric v-slot="props" centered searchable>
                {{ props.row.exempt_from_streak }}
            </b-table-column>
            <b-table-column field="scheduleId" label="schedule id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.scheduleId }}
            </b-table-column>
            <b-table-column field="codeScheduleId" label="code Schedule id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.codeScheduleId }}
            </b-table-column>
            <b-table-column field="codeId" label="code id" sortable numeric v-slot="props" centered searchable>
                {{ props.row.codeId }}
            </b-table-column>
            <b-table-column field="codeCode" label="code" sortable numeric v-slot="props" centered searchable>
                {{ props.row.codeCode }}
            </b-table-column>
            <b-table-column field="codeName" label="code name" v-slot="props" centered searchable>
                {{ props.row.codeName }}
            </b-table-column>
            <b-table-column field="codeSetListNames" label="codeSets" sortable v-slot="props" centered searchable>
                {{ props.row.codeSetListNames }}
            </b-table-column>
            <b-table-column field="dateStr" label="date" sortable date v-slot="props" centered searchable>
                {{ props.row.dateStr }}
            </b-table-column>
            <b-table-column field="startTime" label="startTime" sortable v-slot="props" centered searchable>
                {{ props.row.startTime }}
            </b-table-column>
            <b-table-column field="endTime" label="endTime" sortable v-slot="props" centered searchable>
                {{ props.row.endTime }}
            </b-table-column>
            <b-table-column field="weekNr" label="weekNr" sortable v-slot="props" centered searchable>
                {{ props.row.weekNr }}
            </b-table-column>
            <b-table-column field="monthNr" label="monthNr" sortable v-slot="props" centered searchable>
                {{ props.row.monthNr }}
            </b-table-column>
            <b-table-column field="duration" label="duration" sortable numeric v-slot="props" centered searchable>
                {{ props.row.duration }}
            </b-table-column>
            <b-table-column field="minMaxHoursShift" label="minmax" sortable numeric v-slot="props" centered searchable>
                {{ props.row.minMaxHoursShift }}
            </b-table-column>
            <b-table-column field="quotemValue" label="quotum" sortable numeric v-slot="props" centered searchable>
                {{ props.row.quotemValue }}
            </b-table-column>
            <b-table-column field="type" label="type" sortable v-slot="props" centered searchable>
                {{ props.row.type }}
            </b-table-column>
            <b-table-column field="isHoliday" label="holiday" sortable v-slot="props" centered searchable>
                {{ props.row.isHoliday }}
            </b-table-column>
            <b-table-column field="assignedToUserId" label="assUserId" sortable v-slot="props" centered searchable>
                {{ props.row.assignedToUserId }}
            </b-table-column>
            <b-table-column field="assignedRoles" label="assRoles" sortable v-slot="props" centered searchable>
                {{ props.row.assignedRoles }}
            </b-table-column>
            <b-table-column field="assignedUserPostCoverage" label="assCov" sortable v-slot="props" centered searchable>
                {{ props.row.assignedUserPostCoverage }}
            </b-table-column>
            <b-table-column field="assignedUserPostPerformance" label="assPer" sortable v-slot="props" centered searchable>
                {{ props.row.assignedUserPostPerformance }}
            </b-table-column>
            <b-table-column field="assignedUserMax" label="assMax" sortable v-slot="props" centered searchable>
                {{ props.row.assignedUserMax }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No filtered availability</div>
            </template>

        </b-table>
    </div>

    <AgendaComponent v-if="showAgenda" ref="agenda" :period="agendaPeriod" :events="agendaEvents" style="width: 79%;margin-left: 21%;" @closeAgenda="hideUserAgenda" :holidays="agendaHolidays" :user="agendaUser"/>

  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
import { API_ROOT } from '@/config/app.js';
import MultiSelect from "@/components/public/general/multiSelect.vue";
import MultiSlider from "@/components/public/general/multiSlider.vue";
import SearchInput from "@/components/public/general/searchInput.vue";

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { roundMaxDigits }         from '@/helpers/numbers';
// import { WEB_ROOT, API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

import { WeekYearNr, WeekNr,  } from '@/helpers/dates.js';
// const jsonFormat = require("json-format")

import AgendaComponent from "@/views/public/test/agenda_component.vue";
import myDates from '@/helpers/dates.js';

export default {
  name: "MaximusRun",
  components: {
      MultiSelect,
      MultiSlider,
      SearchInput,
      AgendaComponent
    },

  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,

      pageTitle           : '',

      // WEB_ROOT            : WEB_ROOT,
      API_ROOT            : API_ROOT,
      SITE_ROOT           : process.env.VUE_APP_SITE_MAIN,

      companyId           : null,
      jsonPath            : null,
      runPath             : null,
      file                : null,
      json                : null,
      jsonFiltered        : null,

      // filterSearch        : `mpalmeira89@gmail.com`, //14358
      filterSearch        : ``, //14358
      filterQuotum        :{
        min: 0,
        max: 100,
        value: [1, 100]
      },

      filterPerformance        :{
        min: 0,
        max: 2,
        value: [0, 2]
      },

      filterCoverage        :{
        min: 0,
        max: 1,
        value: [0, 1]
      },

      filterAvailable        :{
        min: 0,
        max: 100,
        value: [0, 100]
      },

      filterAssigned        :{
        min: 0,
        max: 50,
        value: [0, 50]
      },

      filterAssignedPhase        :{
        min: 1,
        max: 2,
        value: [1, 2]
      },
      filterHolidays        :{
        min: 0,
        max: 10,
        value: [0, 10]
      },

      filterRoles         : {
        selectValues      : [],
        selectSet         : new Set([])
      },

      filterUseDepartments  : {
        selectValues      : [],
        selectSet         : new Set([])
      },

      filterLocations  : {
        selectValues      : [],
        selectSet         : new Set([])
      },

      filterSchedules  : {
        selectValues      : [],
        selectSet         : new Set([])
      },

      filterCodeSchedules  : {
        selectValues      : [],
        selectSet         : new Set([])
      },

      filterCodeTypes  : {
        selectValues      : [],
        selectSet         : new Set([])
      },

      filterCodeSets  : {
        selectValues      : [],
        selectSet         : new Set([])
      },

      showAvailability     : false,
      openAvailableOnly    : false,
      showSteaksOnly       : false,
      showLinkedShiftsOnly : false,
      holidaysOnly         : false,
      atwOnly              : false,
      showBlocked          : false,
      showSlots            : false,
      hideLowMaxforStreaks : false,
      locationMisMatch     : false,
      errorMisMatch        : false,
      
      enableAgenda         : false,

      sortOptions:   ['user_id', 'email', 'assiged', 'assignedFiltered', 'quotum', 'performance', 'coverage', 'spreadDeviation'],
      sortOptionSelected: 'assignedFiltered',

      hoverBox             : null,
      hoverBoxAutoCloseDisabled     : false,

      hasLinkedShifts   : false,
      linked_sumText    : '',
      streakShiftIdSet  :       new Set([]),
      // paginationSize       : 50,
      // curPage:              0
      
      userDetailsRows: [],

      showTables: false,
      tableUser: null,
      showFilteredAssignedTable: true,
      tableUserAssigned: [],
      showFilteredAvailabilityTable: false,
      tableUserAvailable: [],
      showFilteredCodeOverview: true,
      tableUserCodeOverview: [],

      
      agendaUser: null,
      showAgenda: false,
      agendaPeriod: {
        from: new Date('2025-01-01'),
        till: new Date('2025-01-01')
      },
      agendaEvents: [],
      agendaHolidays: [],
    }
  },

  async mounted(){
    this.companyId  = this.$route.params.companyId;
    this.jsonPath   = this.$route.params.jsonPath;
    this.runPath    = this.$route.params.runPath;
    this.file       = this.$route.params.file;

    this.pageTitle = this.runPath

    this.getJSONdata()
  },

  methods: {
    async getJSONdata() {
      this.ShowPageLoading(15000)
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const runPath = encodeURIComponent(`${this.runPath}`)
      const fileName = encodeURIComponent(`${this.file}.json`)
      try {
        let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/viewOutput/${jsonPath}/run/${runPath}/${fileName}`);
        // let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/view/${jsonPath}/${fileName}`);
        this.json = response.data
      } catch (err) {
        this.json = {}
      }

      /*console.info(`done`)
      for(let key in this.json) {
        let data = JSON.stringify(this.json[key])
        console.info(`=>${key}: ${data.length}`)
      }*/
      this.preProcessJSON()
      this.updateLinkedShifts()
      this.getAllRoles(this.json.schedules, this.json.users)
      this.getAssignedPhaseValues(this.json.shifts)
      this.getQuotumValues(this.json.users)
      this.getHolidaysValues(this.json.users)
      this.getPerformanceValues(this.json.users)
      this.getCoverageValues(this.json.users)
      this.getUserDepartments(this.json.users)
      this.getLocations(this.json.inputJSON.locations)
      this.getSchedules(this.json.schedules)
      this.getCodeSchedules(this.json.codeSchedules)
      this.getCodeSets(this.json.inputJSON.code_sets, this.json.inputJSON.code_schedules)
      this.getCodeTypes(this.json.shifts)
      this.getStreakShifts(this.json)

      // this.getBlockedDays(this.json.inputJSON.blocked_days)
      // this.getSlots(this.json.inputJSON.reserved_slots)

      // console.info(`loaded completed`)

      this.filterjson()

      this.HidePageLoading()
    },
    weekNrKey(d){
      return `${WeekYearNr(d)}-${WeekNr(d)}`
    },
    monthNrKey(d){
      return `${d.getFullYear()}-${d.getMonth() + 1}`
    },
    removeAssignedUserShifts(userIds){
      for(let userId of userIds){
        let assignedshifts = this.json.shifts.filter(s => s.assignedToUserId === userId)
        for(let assignedshift of assignedshifts) {
          assignedshift.assignedToUserId = null
          assignedshift.assignedToEmail = 'changed'
          assignedshift.assignedToName = 'changed'
          assignedshift.assignedMobile = 'changed'
          assignedshift.assignedBignr = 'changed'
          assignedshift.assignedRoles = 'changed'
          assignedshift.assignedPhaseId = 2
        }
      }
    },
    preProcessJSON(){
      const minDate = new Date(this.json.minDate)
      const maxDate = new Date(this.json.maxDate)
      this.agendaPeriod = {
        from: new Date(this.json.minDate),
        till: new Date(this.json.maxDate)
      }
      this.agendaHolidays = this.json.inputJSON.holidays.map(h => {
        h.start = new Date(h.start)
        h.end = new Date(h.end)
        return h
      })

      /*
      let findUserInputJson = this.json.inputJSON.users.find(u => u.user_id === 15823)
      findUserInputJson.max_per_week = 5
      findUserInputJson.max_per_month = 15
      let findUserMinMaxInputJson = this.json.inputJSON.min_max_hours.find(u => u.user_id === 15823)
      findUserMinMaxInputJson.max_hours = 400
      findUserMinMaxInputJson.max_holidays = 10
      for(let code_preference of findUserMinMaxInputJson.code_preferences) {
        if (typeof(code_preference.code_schedule_ids) === "undefined") continue
        if (code_preference.name === "Avonden") code_preference.max_shifts = 30
        if (code_preference.name === "Weekenden") code_preference.max_shifts = 35
        if (code_preference.name === "Achterwacht") code_preference.max_shifts = 40
        if (code_preference.name === "Nachten") code_preference.max_shifts = 50
      }
      for(let schedule_preference of findUserMinMaxInputJson.schedule_preferences) {
        if (schedule_preference.name === "schedule_min_max_539") schedule_preference.max_hours = 600
      }
      */


      /* tijdelijk */
      /*
        this.removeAssignedUserShifts([15221,14901,12963,5663,15411,7940,14798,2527,19125,15533,15154,19541,14025,15543,9090,15628,15539,15247,15413,14936,14919,15210,4225,3491,14993,3721])

        const findUserInputJson = this.json.inputJSON.users.find(u => u.user_id === 15406)
        findUserInputJson.max_hours_per_month = 30

        const findUser = this.json.users.find(u => u.user_id === 15406)
        findUser.max_hours_per_month = 30

        const findUserMinMaxPref = this.json.inputJSON.min_max_hours.find(u => u.user_id === 15406)
        findUserMinMaxPref.schedule_preferences = []
      */
      /* end tijdelijk */

      /*
      //tmp filter assigned
      let changes = [
          //kimverhagen@ziggo.nl
          { user_id: 14323, shift_id: 827584, remove: true },
          { user_id: 14323, shift_id: 826658, remove: false },

          //mpalmeira89@gmail.com
          { user_id: 18561, shift_id: 829214,  remove: true},
          { user_id: 18561, shift_id: 826746,  remove: true},
          { user_id: 18561, shift_id: 825403,  remove: false},
          { user_id: 18561, shift_id: 829114,  remove: false},

          //thijswieldraaijer@gmail.com
          { user_id: 7025, shift_id: 825233,  remove: false},
          { user_id: 7025, shift_id: 826579,  remove: false},

          //erica.vd.hoeven@gmail.com
          { user_id: 14301, shift_id: 825403,  remove: true},
          { user_id: 14301, shift_id: 826698,  remove: false},

          // brezden@huisartsenpraktijkmozaiek.nl
          { user_id: 5440, shift_id: 827042,  remove: true},
          { user_id: 5440, shift_id: 829553,  remove: false},

          // asitters@outlook.com
          { user_id: 14443, shift_id: 828104,  remove: true},
          { user_id: 14443, shift_id: 827163,  remove: true},
          { user_id: 14443 , shift_id: 825900,  remove: false},

          // iposthuma@shg.nl
          { user_id: 5380, shift_id: 825663,  remove: true},

          // lisanne.mulder86@gmail.com
          { user_id: 23239, shift_id: 827314,  remove: true},

          // hcvanhoolwerff@live.nl
          { user_id: 14252, shift_id: 827266,  remove: true},
          { user_id: 14252, shift_id: 827194,  remove: true},
          { user_id: 14252, shift_id: 825510,  remove: false},
          { user_id: 14252, shift_id: 829819,  remove: false},

          //14528 - s_van_egeraat@hotmail.com
          { user_id: 14528, shift_id: 828104,  remove: false},

          //3349 - sa.huisarts@gmail.com
          { user_id: 3349, shift_id: 828722,  remove: false},

          //14265 - barbara@veenonline.com
          { user_id: 14265, shift_id: 827194,  remove: false},

          //14577 - k.bras@ezorg.nl
          { user_id: 14577, shift_id: 830133,  remove: false},

          //14252 - hcvanhoolwerff@live.nl
          { user_id: 14252, shift_id: 829719,  remove: false},

          //5333 - fmfs.huisartswaarneming@gmail.com
          { user_id: 5333, shift_id: 829028,  remove: false},
      ]

      changes = []

      for(let change of changes) {
        if (!change.remove) continue
        let findShift = this.json.shifts.find(s => s.shiftId === change.shift_id)
        if (!findShift) throw `unable to find shift: ${change.findShift}`
        findShift.assignedToUserId = change.remove ? null : change.user_id
        findShift.assignedToEmail = 'changed'
        findShift.assignedToName = 'changed'
        findShift.assignedMobile = 'changed'
        findShift.assignedBignr = 'changed'
        findShift.assignedRoles = 'changed'
        findShift.assignedPhaseId = null
        // findShift.assignedDepartments = null
      }
      for(let change of changes) {
        if (change.remove) continue
        let findShift = this.json.shifts.find(s => s.shiftId === change.shift_id)
        if (!findShift) throw `unable to find shift: ${change.findShift}`
        findShift.assignedToUserId = change.remove ? null : change.user_id
        findShift.assignedToEmail = 'changed'
        findShift.assignedToName = 'changed'
        findShift.assignedMobile = 'changed'
        findShift.assignedBignr = 'changed'
        findShift.assignedRoles = 'changed'
        findShift.assignedPhaseId = 2
        // findShift.assignedDepartments = null
      }
      console.info(`18561:`, this.json.shifts.filter(s => s.assignedToUserId === 18561))
      */

      let u = {}
      for(let available of this.json.inputJSON.availability) {
        if (typeof(u[available.user_id]) === "undefined") {
          u[available.user_id] = []
        }
        u[available.user_id].push(available)
      }

      let s = {}
      for(let shift of this.json.shifts) {
        shift.start = new Date(shift.startTimeObj)
        shift.end = new Date(shift.endTimeObj)
        // shift.isStreakpart = shift.isStreakpart ? shift.isStreakpart : 0
        shift.codeSetList = this.getCodeScheduleCodeSetList(shift.codeScheduleId)
        shift.codeSetListNames = shift.codeSetList.map(c => c.name).join(`,`)
        s[shift.shiftId] = shift
      }
      this.json.s = s

      // console.info(`pre processing start:`, new Date())
      for(let user of this.json.users) {
        // if (user.user_id !== 14358) continue

        user.assigned = this.json.shifts.filter(s => s.assignedToUserId === user.user_id)
        user.availability = u[user.user_id] ? u[user.user_id] : []

        const userBlocked = this.json.inputJSON.blocked_days.find(b => b.user_id === user.user_id)
        // console.info(`userBlocked:`, userBlocked)
        const userblockedDays = !userBlocked || !userBlocked.blocks ? [] : userBlocked.blocks.map((b) => {
          const start = new Date(b)
          let end = new Date(b)
          end.setDate(start.getDate()+1)
          return {
            start: start,
            end: end
          }
        })
        user.blockedDays = userblockedDays.filter(b => b.start.getTime() <= maxDate.getTime() && b.end.getTime() > minDate.getTime())

        const userSlots = this.json.inputJSON.reserved_slots.find(r => r.user_id === user.user_id)
        const userSlotBlocks = !userSlots || !userSlots.slots ? [] : userSlots.slots.map((s) => {
          s.start = new Date(s.start_datetime)
          s.end = new Date(s.end_datetime)
          return s
        })
        user.userSlots = userSlotBlocks.filter(b => b.start.getTime() <= maxDate.getTime() && b.end.getTime() > minDate.getTime())

        const findUser = this.json.inputJSON.users.find(u => u.user_id === user.user_id) || {}
        user.isAtwUser = findUser && findUser.arbeidstijdenwet === 1 ? true :false

        user.minMaxPref = this.json.inputJSON.min_max_hours.find(u => u.user_id === user.user_id)
        if (!user.minMaxPref) {
          user.minMaxPref = null
        } else {
          if (!user.minMaxPref.code_preferences) user.minMaxPref.code_preferences = []
          if (!user.minMaxPref.schedule_preferences) user.minMaxPref.schedule_preferences = []
        }

        //calc max per month/week per user)
        user.minmax = {}
        user.minmax.general = {
                          key: 'general',
                          count: user.assigned.length,
                          hours: user.assigned.reduce((sum, shift) => { return shift.duration + sum}, 0),
                          minMaxHours: user.assigned.reduce((sum, shift) => { return (shift.minMaxHoursShift ||0) + sum}, 0),
                          quotumHours: user.assigned.reduce((sum, shift) => { return (shift.quotemValue || 0) + sum}, 0)
                        }
        user.minmax.holidays = {}
        user.minmax.week = {}
        user.minmax.weekArr = []
        user.minmax.month = {}
        user.minmax.monthArr = []
        user.minmax.schedule = {}
        user.minmax.scheduleArr = []
        user.minmax.codeSchedule = {}
        user.minmax.codeSets = {}
        user.minmax.codeSetsRef = {}

        //Calc shifts per week
        let cDate = new Date(minDate)
        let mDate = new Date(maxDate)
        mDate.setDate(mDate.getDate() + 7)
        do {
          let weekKey = this.weekNrKey(cDate)
          let userShifts = user.assigned.filter(s => s.weekNr === weekKey)
          const v = {
            key: weekKey,
            count: userShifts.length,
            hours: userShifts.reduce((sum, shift) => { return shift.duration + sum}, 0),
            minMaxHours: userShifts.reduce((sum, shift) => { return (shift.minMaxHoursShift ||0) + sum}, 0),
            quotumHours: userShifts.reduce((sum, shift) => { return (shift.quotemValue || 0) + sum}, 0)
          }
          user.minmax.week[weekKey] = v
          user.minmax.weekArr.push(v)
          cDate.setDate(cDate.getDate() + 7)
        } while(cDate.getTime() <  mDate.getTime())

        //Calc shifts per month
        cDate = new Date(minDate)
        mDate = new Date(maxDate)
        mDate.setMonth(mDate.getMonth() + 1)
        do {
          let monthKey = this.monthNrKey(cDate)
          let userShifts = user.assigned.filter(s => s.monthNr === monthKey)
          const v = {
            key: monthKey,
            count: userShifts.length,
            hours: userShifts.reduce((sum, shift) => { return shift.duration + sum}, 0),
            minMaxHours: userShifts.reduce((sum, shift) => { return (shift.minMaxHoursShift ||0) + sum}, 0),
            quotumHours: userShifts.reduce((sum, shift) => { return (shift.quotemValue || 0) + sum}, 0)
          }
          user.minmax.month[monthKey] = v
          user.minmax.monthArr.push(v)
          cDate.setMonth(cDate.getMonth() + 1)
        } while(cDate.getTime() < mDate.getTime())

        //Calc per schedule
        for(let schedule of this.json.schedules) {
          let findUserScheduleMax = !user.minMaxPref ? null : user.minMaxPref.schedule_preferences.find(p => p.schedule_id === schedule.scheduleId)
          let userShifts = user.assigned.filter(s => s.scheduleId === schedule.scheduleId)
          user.minmax.schedule[schedule.scheduleId] = {
            userPref_min_shifts: findUserScheduleMax ? findUserScheduleMax.min_shifts : null,
            userPref_max_shifts: findUserScheduleMax ? findUserScheduleMax.max_shifts : null,
            userPref_min_hours: findUserScheduleMax ? findUserScheduleMax.min_hours : null,
            userPref_max_hours: findUserScheduleMax ? findUserScheduleMax.max_hours : null,
            scheduleId: schedule.scheduleId,
            scheduleName: schedule.scheduleName,
            count: userShifts.length,
            hours: userShifts.reduce((sum, shift) => { return shift.duration + sum}, 0),
            minMaxHours: userShifts.reduce((sum, shift) => { return (shift.minMaxHoursShift ||0) + sum}, 0),
            quotumHours: userShifts.reduce((sum, shift) => { return (shift.quotemValue || 0) + sum}, 0)
          }

          //TIJDELIJK
          //TIJDELIJK
          // findUserScheduleMax && findUserScheduleMax.min_shifts ? findUserScheduleMax.max_shifts = 999 : null
          // user.minmax.schedule[schedule.scheduleId].userPref_max_shifts = 999
          // user.minmax.schedule[schedule.scheduleId].userPref_max_hours = 999
          // user.minmax.schedule[schedule.scheduleId].userPref_max_shifts = (user.minmax.schedule[schedule.scheduleId].userPref_max_shifts +1) *8
        }

        //Calc per code
        if (user.minMaxPref) {
          for(let code_preference of user.minMaxPref.code_preferences) {
            if (!code_preference.code_schedule_id) continue
            let userShifts = user.assigned.filter(s => s.codeScheduleId === code_preference.code_schedule_id)
            user.minmax.codeSchedule[code_preference.code_schedule_id] = {
              userPref_min: code_preference.min_shifts,
              userPref_max: code_preference.max_shifts,
              userPref_ignore_available: code_preference.ignore_available,

              code_schedule_id: code_preference.code_schedule_id,
              count: userShifts.length,
              hours: userShifts.reduce((sum, shift) => { return shift.duration + sum}, 0),
              minMaxHours: userShifts.reduce((sum, shift) => { return (shift.minMaxHoursShift ||0) + sum}, 0),
              quotumHours: userShifts.reduce((sum, shift) => { return (shift.quotemValue || 0) + sum}, 0)
            }
          }
        }

        //Calc per codeScheduleSet
        if (user.minMaxPref) {
          for(let code_preference of user.minMaxPref.code_preferences) {
            if (!code_preference.code_schedule_ids) continue
            //single code
            let codeSetCodeScheduleIdset = new Set(code_preference.code_schedule_ids)
            let userShifts = user.assigned.filter(s => codeSetCodeScheduleIdset.has(s.codeScheduleId))
            let p = {
              userPref_min: code_preference.min_shifts,
              userPref_max: code_preference.max_shifts,
              userPref_ignore_available: code_preference.ignore_available,
              // TMPcode_preference: code_preference,

              codeSetName: code_preference.name,
              codeSetCodeSchedules: code_preference.code_schedule_ids,
              count: userShifts.length,
              hours: userShifts.reduce((sum, shift) => { return shift.duration + sum}, 0),
              minMaxHours: userShifts.reduce((sum, shift) => { return (shift.minMaxHoursShift ||0) + sum}, 0),
              quotumHours: userShifts.reduce((sum, shift) => { return (shift.quotemValue || 0) + sum}, 0)
            }
            user.minmax.codeSets[code_preference.name] = p

            for(let code_schedule_id of code_preference.code_schedule_ids) {
              if (typeof(user.minmax.codeSetsRef[code_schedule_id]) === "undefined") {
                user.minmax.codeSetsRef[code_schedule_id] = JSON.parse(JSON.stringify(p))
              } else {
                // user.minmax.codeSetsRef[code_schedule_id].scheduleName += `,${p.scheduleName}`
                // user.minmax.codeSetsRef[code_schedule_id].scheduleId += `,${p.scheduleId}`
                user.minmax.codeSetsRef[code_schedule_id].codeSetName += `,${p.codeSetName}`
                user.minmax.codeSetsRef[code_schedule_id].userPref_min = Math.min(user.minmax.codeSetsRef[code_schedule_id].userPref_min, p.userPref_min)
                user.minmax.codeSetsRef[code_schedule_id].userPref_max = Math.min(user.minmax.codeSetsRef[code_schedule_id].userPref_max, p.userPref_max)
                // user.minmax.codeSetsRef[code_schedule_id].userPref_min_hours += Math.min(user.minmax.codeSetsRef[code_schedule_id].userPref_min_hours, p.userPref_min_hours)
                // user.minmax.codeSetsRef[code_schedule_id].userPref_max_hours += Math.min(user.minmax.codeSetsRef[code_schedule_id].userPref_max_hours, p.userPref_max_hours)
              }

            }
          }
        }

        //Calc holidays
        const userHoldaysAssigned = user.assigned.filter(s => s.isHoliday === 1)
        user.minmax.holidays = {
                          key: 'holidays',
                          count: userHoldaysAssigned.length,
                          hours: userHoldaysAssigned.reduce((sum, shift) => { return shift.duration + sum}, 0),
                          minMaxHours: userHoldaysAssigned.reduce((sum, shift) => { return (shift.minMaxHoursShift ||0) + sum}, 0),
                          quotumHours: userHoldaysAssigned.reduce((sum, shift) => { return (shift.quotemValue || 0) + sum}, 0)
                        }


        user.minMaxIssues = []
        if (user.max !== null) {
          if (user.minmax.general.minMaxHours > user.max) user.minMaxIssues.push(`user max hours exceeded ${user.minmax.general.minMaxHours} > ${user.max}`)
        }

        if (user.max_per_week !== null) {
          const userMaxWeekshiftsExceded = user.minmax.weekArr.filter(w => w.count > user.max_per_week)
          if (userMaxWeekshiftsExceded.length > 0 ) {
            user.minMaxIssues.push(`user max shifts per week exceeded ${userMaxWeekshiftsExceded.map(w => `${w.key}=>${w.count}`).join(`, `)}`)
          }
        }

        if (user.max_hours_per_week !== null) {
          const userMaxWeekHoursExceded = user.minmax.weekArr.filter(w => w.minMaxHours > user.max_hours_per_week)
          if (userMaxWeekHoursExceded.length > 0 ) {
            user.minMaxIssues.push(`user max hours per week exceeded ${userMaxWeekHoursExceded.map(w => `${w.key}=>${w.minMaxHours}`).join(`, `)}`)
          }
        }

        if (user.max_per_month !== null) {
          const userMaxMonthshiftsExceded = user.minmax.monthArr.filter(w => w.count > user.max_per_month)
          if (userMaxMonthshiftsExceded.length > 0 ) {
            user.minMaxIssues.push(`user max shifts per month exceeded ${userMaxMonthshiftsExceded.map(w => `${w.key}=>${w.count}`).join(`, `)}`)
          }
        }

        if (user.max_hours_per_month !== null) {
          const userMaxMonthHoursExceded = user.minmax.monthArr.filter(w => w.minMaxHours > user.max_hours_per_month)
          if (userMaxMonthHoursExceded.length > 0 ) {
            user.minMaxIssues.push(`user max hours per month exceeded ${userMaxMonthHoursExceded.map(w => `${w.key}=>${w.minMaxHours}`).join(`, `)}`)
          }
        }

        if (user.max_holidays !== null) {
          if (user.minmax.holidays.count > user.max_holidays) user.minMaxIssues.push(`user max holidays exceeded ${user.minmax.holidays.count} > ${user.max_holidays}`)
        }


      }



      let uL = {}
      for(let user of this.json.users) {
        uL[user.user_id] = user
      }
      this.json.u = uL

      const totalShifts = this.json.shifts.length
      const totalAssigned = this.json.shifts.filter(s => s.assignedToUserId !== null).length
      this.pageTitle = `${this.runPath} - shifts:${totalShifts} - ${ ((totalAssigned/totalShifts)*100).toFixed(1)}%`

      // console.info(`pre processing end:`, new Date())

    },
    updateLinkedShifts(){

      this.hasLinkedShifts = false
      if (!this.json.inputJSON.linked_shifts) return
      if (this.json.inputJSON.linked_shifts.length <= 0) return
      this.hasLinkedShifts = true
      // console.info(`updateLinkedShifts:`)

      const linked_shifts = this.json.inputJSON.linked_shifts
      let shifts = this.json.shifts
      for(let shift of shifts) {
        shift.linked = false
        shift.linkedFull = false
        shift.linkedGroup = []
      }

      let allPairShifts = []
      let fullPairAssigned = []

      let pairCount = 0
      let pairFilledCount = 0
      for(let linked_shift of linked_shifts) {
        allPairShifts = [...allPairShifts, ...linked_shift.shift_ids[0], ...linked_shift.shift_ids[1]]
        for(let allPairShift of allPairShifts) {
          // console.info(`this.json.s[allPairShift]:`, this.json.s[allPairShift])
          this.json.s[allPairShift].linked = true
          this.json.s[allPairShift].linkedGroup.push(linked_shift)
        }

        pairCount += Math.min(linked_shift.shift_ids[0].length, linked_shift.shift_ids[1].length)
        let find0 = shifts.filter(s => new Set(linked_shift.shift_ids[0]).has(s.shiftId) && s.assignedToUserId !== null)
        let find1 = shifts.filter(s => new Set(linked_shift.shift_ids[1]).has(s.shiftId) && s.assignedToUserId !== null)
        let userIds = new Set([...find0.map(s => s.assignedToUserId), ...find1.map(s => s.assignedToUserId)])

        for(let userId of [...userIds]) {
          let shift0 = find0.find(s => s.assignedToUserId === userId)
          if (!shift0) continue

          let shift1 = find1.find(s => s.assignedToUserId === userId)
          if (!shift1) continue
          find0.linkedFull = true
          find1.linkedFull = true

          // console.info(`pair found userId:${userId} - ${shift0.date} [${shift0.codeCode} + ${shift1.codeCode}] ${shift0.startTime}-${shift0.endTime} + ${shift1.startTime}-${shift1.endTime} --> shiftIds:[${shift0.shiftId}-${shift1.shiftId}]`)
          fullPairAssigned.push(shift0.shiftId)
          fullPairAssigned.push(shift1.shiftId)

          pairFilledCount +=1
        }
      }
      console.info(`allPairAssigned:`, allPairShifts.length)
      console.info(`fullPairAssigned:`, fullPairAssigned.length)
      //create full assigned file
      let fullJSON = []
      let halfJSON = []
      let fullPairAssignedSet = new Set(fullPairAssigned)
      for(let allPairShift of allPairShifts) {
        // console.info(`allPairShift:`, allPairShift)
        // process.exit()
        let shift = shifts.find(s => s.shiftId === allPairShift)
        if (!shift.assignedToUserId) continue

        if (fullPairAssignedSet.has(shift.shiftId)) {
          fullJSON.push({
            shift_id: shift.shiftId,
            user_id: shift.assignedToUserId
          })
        } else {
          halfJSON.push({
            shift_id: shift.shiftId,
            user_id: shift.assignedToUserId
          })
        }
      }

      console.info(`code pair count: ${pairCount}`)
      console.info(`code pair filled Full: ${pairFilledCount}`)
      console.info(`code pair filled Full ${Math.round((pairFilledCount / pairCount) * 10000)/100}%`)
      console.info(`----`)
      console.info(`code pair shifts filled Half: ${halfJSON.length}` )
      console.info(`code pair shifts filled Half+Full ${Math.round(((fullJSON.length + halfJSON.length) / allPairShifts.length) * 10000)/100}%`)
      this.linked_sumText = ` codepairs: ${pairCount} - full:${pairFilledCount}(${Math.round((pairFilledCount / pairCount) * 10000)/100}%) - half:${halfJSON.length}`
      this.pageTitle += this.linked_sumText
    },
    getStreakShifts(){
      let streaks = this.json.inputJSON.streaks
      if (!streaks || streaks.length <=0) return false
      let streakCodeScheduleIds = new Set([])
      for(let streak of streaks) {
        for(let codeSchedule of streak.code_schedule_ids) {
          streakCodeScheduleIds.add(codeSchedule)
        }
      }
      if (streakCodeScheduleIds.size === 0) return false

      this.shiftsWithStreakOption = this.json.shifts.filter(s => streakCodeScheduleIds.has(s.codeScheduleId))
      this.streakShiftIdSet = new Set(this.shiftsWithStreakOption.map(s => s.shiftId))
      // console.info(`this.streakShiftIdSet:`, this.streakShiftIdSet)
    },
    getCodeTypes(shifts){
      let codeTypeSet = new Set([])
      let list = []
      for(let shift of shifts){
        if (!shift.type) continue
        if (codeTypeSet.has(shift.type)) continue

        let shifts = this.json.shifts.filter(s => codeTypeSet.has(s.type)) || []
        let assigned = shifts.filter(s => s.assignedToUserId !== null) || []
        let assignedPercent = assigned.length <= 0 ?  0 : (assigned.length / shifts.length) * 100

        codeTypeSet.add(shift.type)
        list.push({
          id: shift.type,
          name: shift.type,
          label: `${shift.type} ${shifts.length} - ${assignedPercent.toFixed(1)}%`,
          value: true
        })
      }
      this.filterCodeTypes.selectValues = list
      this.filterCodeTypes.selectSet = this.getCodeTypeSelectedItems(this.filterCodeTypes.selectValues)
      // console.info(`this.filterCodeTypes:`, this.filterCodeTypes)
    },
    getCodeSets(codeSets, codeSchedules){
      let list = []
      let allCodeSetCodeSchedules = new Set([])
      for(let codeSet of codeSets){
        allCodeSetCodeSchedules = new Set([...allCodeSetCodeSchedules, ...codeSet.code_schedule_ids])
        let codeScheduleIdSet = new Set(codeSet.code_schedule_ids)

        let shifts = this.json.shifts.filter(s => codeScheduleIdSet.has(s.codeScheduleId))
        let assigned = shifts.filter(s => s.assignedToUserId !== null)
        let assignedPercent = assigned.length <= 0 ?  0 : (assigned.length / shifts.length) * 100

        list.push({
          id: codeSet.id,
          name: codeSet.name,
          code_schedule_ids: codeSet.code_schedule_ids,
          codeSchedules: codeSchedules.filter(c => codeScheduleIdSet.has(c.code_schedule_id)),
          label: `${codeSet.name} ${shifts.length} - ${assignedPercent.toFixed(1)}%`,
          value: true
        })
      }
      this.filterCodeSets.selectValues = list
      this.filterCodeSets.selectSet = this.getCodeSetSelectedItems(this.filterCodeSets.selectValues)
      this.filterCodeSets.allCodeSetCodeSchedules = allCodeSetCodeSchedules
      // console.info(`this.filterCodeSets:`, this.filterCodeSets)
    },
    getCodeSetSelectedItems(codeSetValues){
      let list = new Set([])
      for(let codeSet of codeSetValues) {
        if (codeSet.value !== true) continue
        for(let code_schedule_id of codeSet.code_schedule_ids){
          list.add(code_schedule_id)
        }

      }
      // console.info(`getCodeSetSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getCodeTypeSelectedItems(codeTypeValues){
      let list = new Set([])
      for(let codeType of codeTypeValues) {
        if (codeType.value !== true) continue

        list.add(codeType.name)

      }
      // console.info(`getCodeTypeSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getUserDepartments(users){
      let departmentSet = new Set([])
      let list = []
      for(let user of users) {
        // console.info(`user:`, user)
        if (!user.departmentsList) continue
        if (user.departmentsList.length <=0) continue
        for(let department of user.departmentsList) {
          if (departmentSet.has(department)) continue
          departmentSet.add(department)
          // console.info(`department:`, department)
          let findLocation = this.json.inputJSON.locations.find(l => l.id === department)
          if (!findLocation) continue
          // console.info(`findLocation:`, findLocation)

          let shifts = this.json.shifts.filter(s => s.departmentId === findLocation.id)
          let assigned = shifts.filter(s => s.assignedToUserId !== null)
          let assignedPercent = assigned.length <= 0 ?  0 : (assigned.length / shifts.length) * 100
          let usersWithDepartment = users.filter(u => new Set(u.departmentsList).has(department))

          list.push({
            id: findLocation.id,
            name: findLocation.name,
            prefix: findLocation.prefix ? findLocation.prefix : '',
            label: findLocation.is_head_office === 1 ? `${findLocation.id}-${findLocation.name}-(main)` : `${findLocation.id}-${findLocation.name} - (${usersWithDepartment.length}) ${assignedPercent.toFixed(1)}%`,
            value: true,
          })
        }
      }
      // console.info(`list:`, list)
      this.filterUseDepartments.selectValues = list
      this.filterUseDepartments.selectSet = this.getUserDepartmentsSelectedItems(this.filterUseDepartments.selectValues)
      // console.info(`this.filterLocations:`, this.filterLocations)
    },
    getLocations(locations){
      this.filterLocations.selectValues = locations.map(location=>{

        let shifts = this.json.shifts.filter(s => s.departmentId === location.id)
        let assigned = shifts.filter(s => s.assignedToUserId !== null)
        let assignedPercent = assigned.length <= 0 ?  0 : (assigned.length / shifts.length) * 100

        return {
          id: location.id,
          name: location.name,
          prefix: location.prefix,
          label: location.is_head_office === 1 ? `${location.id}-${location.name}-(main)` : `${location.id}-${location.name} - (${shifts.length}) ${assignedPercent.toFixed(1)}%`,
          value: true,
        }
      })
      this.filterLocations.selectSet = this.getLocationSelectedItems(this.filterLocations.selectValues)
      // console.info(`this.filterLocations:`, this.filterLocations)
    },
    getSchedules(schedules){
      this.filterSchedules.selectValues = schedules.map(s=>{

        let shifts = this.json.shifts.filter(shift => shift.scheduleId === s.scheduleId)
        let assigned = shifts.filter(s => s.assignedToUserId !== null)
        let assignedPercent = assigned.length <= 0 ?  0 : (assigned.length / shifts.length) * 100

        return {
          id: s.scheduleId,
          name: s.scheduleName,
          label: `${s.scheduleId}-${s.scheduleName} - (${shifts.length}) ${assignedPercent.toFixed(1)}%`,
          accessRoles: s.accessRoles,
          value: true,
        }
      })
      this.filterSchedules.selectSet = this.getScheduleSelectedItems(this.filterSchedules.selectValues)
      // console.info(`this.filterSchedules:`, this.filterSchedules)
    },
    getCodeSchedules(codeSchedules){
      let codeScheduleList = []
      let codeScheduleSet = new Set([])
      for(let codeSchedule of codeSchedules){
        if (codeScheduleSet.has(codeSchedule.codeCode)) continue
        codeScheduleSet.add(codeSchedule.codeCode)

        let shifts = this.json.shifts.filter(s => s.codeScheduleId === codeSchedule.codeScheduleId)
        let assigned = shifts.filter(s => s.assignedToUserId !== null)
        let assignedPercent = assigned.length <= 0 ?  0 : (assigned.length / shifts.length) * 100
        let streaks = this.json.inputJSON.streaks.filter(s => new Set(s.code_schedule_ids).has(codeSchedule.codeScheduleId))
        let streakText = streaks.length <= 0 ? '' : `[ ${streaks.map(s => s.name).join(', ')} ]`

        codeScheduleList.push({
          code: codeSchedule.codeCode,
          start: codeSchedule.startTime,
          end: codeSchedule.endTime,
          shifts: codeSchedule.shifts,
          assigned: codeSchedule.assignedCount,
          label: `${codeSchedule.codeScheduleId}-${codeSchedule.codeCode} ${codeSchedule.startTime}-${codeSchedule.endTime} - ${shifts.length} - ${assignedPercent.toFixed(1)}% ${streakText}`,
          value: true
        })
      }

      codeScheduleList.sort((a,b) => {
        if (a.code.toLowerCase() < b.code.toLowerCase()) return -1
        if (a.code.toLowerCase() < b.code.toLowerCase()) return 1
        return 0
      })

      this.filterCodeSchedules.selectValues = codeScheduleList
      this.filterCodeSchedules.selectSet = this.getCodeScheduleSelectedItems(this.filterCodeSchedules.selectValues)
    },
    getUserDepartmentsSelectedItems(departmentValues){
      let list = new Set([])
      for(let department of departmentValues) {
        if (department.value !== true) continue
        // console.info(`-->>>codeSchedule:`, codeSchedule)
        list.add(department.id)
      }
      // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getLocationSelectedItems(locationValues){
      let list = new Set([])
      for(let location of locationValues) {
        if (location.value !== true) continue
        // console.info(`-->>>codeSchedule:`, codeSchedule)
        list.add(location.id)
      }
      // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getScheduleSelectedItems(scheduleValues){
      let list = new Set([])
      for(let schedule of scheduleValues) {
        if (schedule.value !== true) continue
        // console.info(`-->>>codeSchedule:`, codeSchedule)
        list.add(schedule.id)
      }
      // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getCodeScheduleSelectedItems(codeScheduleValues){
      let list = new Set([])
      for(let codeSchedule of codeScheduleValues) {
        if (codeSchedule.value !== true) continue
        // console.info(`-->>>codeSchedule:`, codeSchedule)
        list.add(codeSchedule.code.toLocaleLowerCase())
      }
      // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getAssignedPhaseValues(shifts){
      let min = 1
      let max = 2
      for(let shift of shifts) {
        if (shift.assignedPhaseId === null) continue
        min = Math.min(min, shift.assignedPhaseId)
        max = Math.max(max, shift.assignedPhaseId)
      }
      max = Math.min(max, 10) //cap at 10
      this.filterAssignedPhase = {
        min,
        max,
        value: [min, max]
      }
      // console.info(`this.filterQuotum:`, this.filterQuotum)
    },
    getQuotumValues(users){
      let min = 0
      let max = 1
      for(let user of users) {
        if (user.min !== null) min = Math.min(min, user.min)
        if (user.max !== null) max = Math.max(max, user.max)
      }
      max = Math.min(max, 100) //cap at 100
      this.filterQuotum = {
        min,
        max,
        value: [0, max]
      }
      // console.info(`this.filterQuotum:`, this.filterQuotum)
    },
    getPerformanceValues(users){
      let min = 0
      let max = 1
      for(let user of users) {
        if (user.postPerformance !== null) min = Math.min(min, user.postPerformance)
        if (user.postPerformance !== null) max = Math.max(max, user.postPerformance)
      }
      max = Math.min(max, 2) //cap at 2
      this.filterPerformance = {
        min,
        max,
        value: [0, max]
      }
      // console.info(`this.getPerformanceValues:`, this.filterPerformance)
    },
    getHolidaysValues(users){
      let min = 0
      let max = 1
      for(let user of users) {
        let holidaysAssigned = user.assigned.filter(s => s.isHoliday === 1).length
        if (holidaysAssigned !== null) min = Math.min(min, holidaysAssigned)
        if (holidaysAssigned !== null) max = Math.max(max, holidaysAssigned)
      }
      max = Math.min(max, 10) //cap at 1
      this.filterHolidays = {
        min,
        max,
        value: [0, max]
      }
      // console.info(`this.getCoverageValues:`, this.filterCoverage)
    },
    getCoverageValues(users){
      let min = 0
      let max = 1
      for(let user of users) {
        if (user.postCoverage !== null) min = Math.min(min, user.postCoverage)
        if (user.postCoverage !== null) max = Math.max(max, user.postCoverage)
      }
      max = Math.min(max, 1) //cap at 1
      this.filterCoverage = {
        min,
        max,
        value: [0, max]
      }
      // console.info(`this.getCoverageValues:`, this.filterCoverage)
    },
    getAllRoles(schedules, users){
      //schedule access roles
      let addedRoles = new Set([])
      for(let schedule of schedules) {
        if (!schedule.accessRoles || schedule.accessRoles.length <=0) continue
        for(let role of schedule.accessRoles){
          addedRoles.add(role.name)
        }
      }

      //include all user roles
      for(let user of users) {
        if (!user.rolesList) continue
        for(let role of user.rolesList) {
          addedRoles.add(role)
        }
      }

      // console.info(`roles:`, roleSet)
      // return
      let rolesArray = [...addedRoles]
      rolesArray.sort((a,b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() < b.toLowerCase()) return 1
        return 0
      })

      let roleSelectValues = []
      for(let role of rolesArray) {
        let shiftAssignedToRole = this.json.shifts.filter(s =>
          s.assignedRoles !== null &&
          new Set(s.assignedRoles.toLowerCase().split(`, `).map(r => r.trim())).has(role.trim().toLocaleLowerCase())
        )

        let assignedPercent = shiftAssignedToRole.length <= 0 ? 0 : (shiftAssignedToRole.length / this.json.shifts.length) * 100
        let userCount = users.filter(u => new Set(u.rolesList).has(role))
        // roleSet.add(`${role} - ${assignedPercent.toFixed(1)}%`)

        roleSelectValues.push({
          id: role,
          name: role,
          label: `${role} (${userCount.length}) - ${assignedPercent.toFixed(1)}%`,
          value: true,
        })
      }

      // this.filterRoles.selectValues = this.arrayToBoleanObjArray(rolesArray)
      this.filterRoles.selectValues = roleSelectValues
      this.filterRoles.selectSet = this.getRoleSetSelectedItems(this.filterRoles.selectValues)
    },
    getRoleSetSelectedItems(roleSetValues){
      let list = new Set([])
      for(let role of roleSetValues) {
        if (role.value !== true) continue
        list.add(role.name.toLowerCase())
      }
      // console.info(`getCodeSetSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    updateSelectedRoles(){
      // this.filterRoles.selectSet = this.getArrayTrueValueSet(this.filterRoles.selectValues)
      this.filterRoles.selectSet = this.getRoleSetSelectedItems(this.filterRoles.selectValues)
      // console.info(`--> this.filterRoles.selectSet:`, this.filterRoles.selectSet)
    },
    updateSelectedLocations(){
      this.filterLocations.selectSet = this.getLocationSelectedItems(this.filterLocations.selectValues)
      // console.info(`this.filterLocations.selectSet:`, this.filterLocations.selectSet)
    },
    updateSelectedUserDepartments(){
      this.filterUseDepartments.selectSet = this.getUserDepartmentsSelectedItems(this.filterUseDepartments.selectValues)
      // console.info(`this.filterLocations.selectSet:`, this.filterLocations.selectSet)
    },
    updateSelectedSchedules(){
      this.filterSchedules.selectSet = this.getScheduleSelectedItems(this.filterSchedules.selectValues)
      // console.info(`this.filterSchedules.selectSet:`, this.filterSchedules.selectSet)
    },
    updateSelectedCodeSchedules(){
      this.filterCodeSchedules.selectSet = this.getCodeScheduleSelectedItems(this.filterCodeSchedules.selectValues)
      // console.info(`this.filterCodeSchedules.selectSet:`, this.filterCodeSchedules.selectSet)
    },
    updateSelectedCodeTypes(){
      this.filterCodeTypes.selectSet = this.getCodeTypeSelectedItems(this.filterCodeTypes.selectValues)
      // console.info(`==>this.updateSelectedCodeTypes.selectSet:`, this.filterCodeSets.selectSet)
    },
    updateSelectedCodeSets(){
      this.filterCodeSets.selectSet = this.getCodeSetSelectedItems(this.filterCodeSets.selectValues)
      // console.info(`==>this.updateSelectedCodeTypes.selectSet:`, this.filterCodeSets.selectSet)
    },
    updateFilters(){
      this.filterjson()
    },
    updateFilterQuotum(newVal){
      this.filterQuotum.value = newVal
    },
    updateFilterPerformance(newVal){
      this.filterPerformance.value = newVal
    },
    updateFilterCoverage(newVal){
      this.filterCoverage.value = newVal
    },
    updateFilterAssigned(newVal){
      this.filterAssigned.value = newVal
    },
    updateFilterAssignedPhase(newVal){
      this.filterAssignedPhase.value = newVal
    },
    updateFilterHolidays(newVal){
      this.filterHolidays.value = newVal
    },
    updateFilterAvailable(newVal){
      this.filterAvailable.value = newVal
    },
    updateSearch(val){
      this.filterSearch = val
    },
    getArrayTrueValueSet(obj) {
      let list = new Set([])
      for(let item of obj) {
        if (item.value !== true) continue
        list.add(item.label.toLowerCase())
      }
      // console.info(`getArrayTrueValueSet:`, list)
      return list
    },
    arrayToBoleanObjArray(array) {
      let list = []
      for(let item of array) {
        list.push({
          label: item,
          value: true
        })
      }
      return list
    },
    hasUserMustHaveRole(userRoles){
      if (this.filterRoles.selectSet.size === this.filterRoles.selectValues.length) return true //show all if no filter set!
      for(let userRole of userRoles) {
        if (this.filterRoles.selectSet.has(userRole.toLowerCase())) return true
      }
      return false
    },
    hasSearchMatch(user){
      let search = this.filterSearch.toLocaleLowerCase().trim()
      // console.info(`search:`, search)
      if (search === '') return true

      // let roleStr = user.rolesList && user.rolesList.length > 0 ? user.rolesList.join(`|`) : 'no-role'
      // let userSearchString = `${user.user_id}|${user.company_user_id}|${user.email}|${roleStr}|${user.mobile}`.toLocaleLowerCase()
      let userSearchString = `${user.email}`.toLocaleLowerCase()

      search = search.split(`\n`).join(` `)
      search = search.split(`\r`).join(` `)
      search = search.split(`\t`).join(` `)
      search = search.split(`,`).join(` `)
      let items = search.split(` `)
      for(let item of items) {
        item = item.trim()
        if (item === '') continue
        if (parseInt(item) === user.user_id) return true
        if (parseInt(item) === user.company_user_id) return true
        if (userSearchString.indexOf(item) > -1) return true
      }

      return false
    },
    hasQuotumMatch(user) {
      let userQuotum = user.quotum ? parseFloat(user.quotum) : 0
      if (userQuotum < parseInt(this.filterQuotum.value[0])) return false
      if (this.filterQuotum.value[1] < this.filterQuotum.max && userQuotum > parseInt(this.filterQuotum.value[1])) return false
      return true
    },
    hasPerformanceMatch(user) {
      let userPerformance = user.postPerformance ? parseFloat(user.postPerformance) : 0
      if (userPerformance < parseFloat(this.filterPerformance.value[0])) return false
      if (this.filterPerformance.value[1] < this.filterPerformance.max && userPerformance > parseFloat(this.filterPerformance.value[1])) return false
      return true
    },
    hasUserLocation(user) {
      // console.info(`user:`, user.departmentsList)
      let selectedDepartmentsSet = this.getUserDepartmentsSelectedItems(this.filterUseDepartments.selectValues)
      if (selectedDepartmentsSet.size === this.filterUseDepartments.selectValues.length) return true

      if (!user.departmentsList) return false
      if (user.departmentsList.length <=0) return false
      for(let department of user.departmentsList){
        if (selectedDepartmentsSet.has(department)) return true
      }
      return false
    },
    hasCoverageMatch(user) {
      let userCoverage = user.postCoverage ? parseFloat(user.postCoverage) : 0
      if (userCoverage < parseFloat(this.filterCoverage.value[0])) return false
      if (this.filterCoverage.value[1] < this.filterCoverage.max && userCoverage > parseFloat(this.filterCoverage.value[1])) return false
      return true
    },
    hasAtwUser(user) {
      if (this.atwOnly === false) return true
      return user.isAtwUser
    },
    hasErrorMatch(user) {
      if (this.errorMisMatch === false) return true
      return user.hasIssue
    },
    hasOpenAvailableMinMaxAnd(user, shift){
      // console.info(`user:`, user)
      /* eslint-disable */

      // console.info(`hasOpenAvailableMinMaxAnd:`, {user, shift})
      // https://www.codegrepper.com/code-examples/javascript/frameworks/build/eslint-disable-next-line+no-case-declarations+disable+rule

      // return false

      // const highPerformanceUserIds = new Set([4085,8766,14376,4519,14778,7315,14582,7064,14342,17691,18243,5668,7038,14094,7386,12085,3316,14450,5258,6262,8910,1762,6227,5391,7613,2027,6247,5255,14241,5237,7505,4798,4849,2205,6385,7916,15587,6520,14827,5076,6825,14718,15518,8571,14300,2207,14662,14361,6129,14658,4163,18459,14712,3271,14231,2191,14776,9484])
      //const highPerformanceUserIds = new Set([4085,8766,14376,4519,14778,7315,14582,7064,14342,17691,18243,5668,7038,14094,7386,12085,3316,14450,5258,6262,8910,1762])
      // const highPerformanceUserIds = new Set([])


      if (!this.showAvailability) return true
      if (!this.openAvailableOnly) return true
      // if (shift.assignedToUserId !== null) return false

      // if (shift.assignedToUserId !== null && !highPerformanceUserIds.has(shift.assignedToUserId)) return false

      //max hours
      if (this.hasValue(user.max) && user.minmax.general.minMaxHours + shift.minMaxHoursShift >= user.max) return false

      //max holidays
      if (this.hasValue(user.max_holidays) && shift.isHoliday === 1 && user.minmax.holidays.count >= user.max_holidays) return false

      // //max shifts per week
      if (this.hasValue(user.max_per_week) && user.minmax.week[shift.weekNr] && user.minmax.week[shift.weekNr].count >= user.max_per_week) return false
      if (this.hasValue(user.max_hours_per_week) && user.minmax.week[shift.weekNr] && user.minmax.week[shift.weekNr].hours + shift.minMaxHoursShift > user.max_hours_per_week) return false

      //max shifts per month
      if (this.hasValue(user.max_per_month) && user.minmax.month[shift.monthNr] && user.minmax.month[shift.monthNr].count >= user.max_per_month) return false
      if (this.hasValue(user.max_hours_per_month) && user.minmax.month[shift.monthNr] && user.minmax.month[shift.monthNr].hours + shift.minMaxHoursShift > user.max_hours_per_month) return false
      // return true

      //schedule
      if (user.minmax.schedule[shift.scheduleId]){
        if (this.hasValue(user.minmax.schedule[shift.scheduleId].userPref_max_shifts) && user.minmax.schedule[shift.scheduleId].count >= user.minmax.schedule[shift.scheduleId].userPref_max_shifts) return false
        if (this.hasValue(user.minmax.schedule[shift.scheduleId].userPref_max_hours) && user.minmax.schedule[shift.scheduleId].hours + shift.minMaxHoursShift >= user.minmax.schedule[shift.scheduleId].userPref_max_hours) return false
      }
      // return true


      //CodeSchedule
      if (this.hasValue(user.minmax.codeSchedule[shift.codeScheduleId] && user.minmax.codeSchedule[shift.codeScheduleId].userPref_max)){
        if (user.minmax.codeSchedule[shift.codeScheduleId].count >= user.minmax.codeSchedule[shift.codeScheduleId].userPref_max) return false
      }


      //CodeSchedule Set
      if (this.hasValue(user.minmax.codeSetsRef[shift.codeScheduleId] && user.minmax.codeSetsRef[shift.codeScheduleId].userPref_max)){
        // console.info(`codeScheduleId: ${shift.codeScheduleId}-${shift.codeName} cur ${user.minmax.codeSetsRef[shift.codeScheduleId].count} > user pref ${user.minmax.codeSetsRef[shift.codeScheduleId].userPref_max}`)
        // console.info(`user.minmax.codeSetsRef[shift.codeScheduleId]:`, user.minmax.codeSetsRef[shift.codeScheduleId])
        if (user.minmax.codeSetsRef[shift.codeScheduleId].count >= user.minmax.codeSetsRef[shift.codeScheduleId].userPref_max) return false
      }


      return true
    },
    hasAvailableMatch(user) {
      if (!this.showAvailability) return true
      let userAvailablity = user.availabilityFiltered ? user.availabilityFiltered.length : 0
      if (userAvailablity < parseInt(this.filterAvailable.value[0])) return false
      if (this.filterAvailable.value[1] < this.filterAvailable.max && userAvailablity > parseInt(this.filterAvailable.value[1])) return false
      return true
    },
    hasAssignedMatch(user) {
      let userAssigned = user.assignedFiltered ? user.assignedFiltered.length : 0
      if (userAssigned < parseInt(this.filterAssigned.value[0])) return false
      if (this.filterAssigned.value[1] < this.filterAssigned.max && userAssigned > parseInt(this.filterAssigned.value[1])) return false
      return true
    },
    hasStreakLowMaxAvailability(user){
      if (!this.showSteaksOnly) return true
      if (!this.hideLowMaxforStreaks) return true
      /* eslint-disable */
      // console.info(`user:`, user)
      let userMaxWeek = user.max_per_week || 0
      // console.info(`userMaxWeek:`, userMaxWeek)
      let userMaxMonth = user.max_per_month || 0
      // console.info(`userMaxMonth:`, userMaxMonth)

      let userAssignedStreaks = user.assigned.filter(s => s.isStreakpart === 1)
      if (userAssignedStreaks.length > 0) return true

      let userAvailability = user.availabilityFiltered.length || 0

      let minStreakLen = 99
      for(let streak of this.json.streaks) {
        minStreakLen = Math.min(minStreakLen, streak.min_streak)
      }
      // console.info(`minStreakLen:`, minStreakLen)
      if (userMaxWeek < minStreakLen) return false
      if (userMaxMonth < minStreakLen) return false
      if (userAvailability < minStreakLen) return false
      return true
    },
    hasAssignedHolidaysMatch(user) {
      let holidaysAssigned = (user.assigned.filter(s => s.isHoliday === 1)).length

      if (holidaysAssigned < parseInt(this.filterHolidays.value[0])) return false
      if (this.filterHolidays.value[1] < this.filterHolidays.max && holidaysAssigned > parseInt(this.filterHolidays.value[1])) return false
      return true
    },
    hasStreakPart(availability) {
      if (this.showSteaksOnly === false) return true
      if (this.streakShiftIdSet.size === 0) return false
      if (availability.exempt_from_streak === 1) return false
      if (this.json.s[availability.shift_id].streaks.length <= 0) return false


      return this.streakShiftIdSet.has(availability.shift_id)

    },
    hasAssignedPhaseMatch(shift) {
      let phase = shift.assignedPhaseId === null ? 0 : shift.assignedPhaseId
      if (phase < parseInt(this.filterAssignedPhase.value[0])) return false
      if (this.filterAssignedPhase.value[1] < this.filterAssignedPhase.max && phase > parseInt(this.filterAssignedPhase.value[1])) return false
      return true
    },
    hasLinkedShiftMatch(shift){
      if (!this.hasLinkedShifts) return true
      if (!this.showLinkedShiftsOnly) return true
      return shift.linked
    },
    hasLocationMatch(shiftDepartmentId, userDepartments){
      if (this.locationMisMatch === false) return true
      return this.isDepartMentMisMatch(shiftDepartmentId, new Set(userDepartments))
    },
    sortUserList(userList){
      if (this.sortOptionSelected === 'assignedFiltered') {
        userList.sort((a,b) => {
          if (b.assignedFiltered.length === a.assignedFiltered.length) {
            return b.user_id - a.user_id
          }
          return b.assignedFiltered.length - a.assignedFiltered.length
        })
      } else if (this.sortOptionSelected === 'assiged') {
        userList.sort((a,b) => {
          if (b.assigned.length === a.assigned.length) {
            return b.availability.length - a.availability.length
          }
          return b.assigned.length - a.assigned.length
        })
      } else if (this.sortOptionSelected === 'user_id') {
        userList.sort((a,b) => {
          return a.user_id - b.user_id
        })
      } else if (this.sortOptionSelected === 'email') {
        userList.sort((a,b) => {
          if (b.email.toLowerCase() < a.email.toLowerCase()) return 1
          if (b.email.toLowerCase() > a.email.toLowerCase()) return -1
          return 0
        })
      } else if (this.sortOptionSelected === 'quotum') {
        userList.sort((a,b) => {
          return a.quotum - b.quotum
        })
      } else if (this.sortOptionSelected === 'performance') {
        userList.sort((a,b) => {
          return b.postPerformance - a.postPerformance
        })
      } else if (this.sortOptionSelected === 'coverage') {
        userList.sort((a,b) => {
          return b.postCoverage - a.postCoverage
        })
      } else if (this.sortOptionSelected === 'spreadDeviation') {
        userList.sort((a,b) => {
          return b.spreadDeviation - a.spreadDeviation
        })
      }
      return userList
    },
    hasUserErrors(user) {
      if (user.errMax.length > 0) return true
      if (user.errCodeSetMax.length > 0) return true
      if (user.errCodeMax.length > 0) return true
      if (user.errMaxPerWeek.length > 0) return true
      if (user.errMaxPerMonth.length > 0) return true
      if (user.errMaxHolidays.length > 0) return true
      if (user.errTimeBetweenShifts.length > 0) return true
      if (user.errOther.length > 0) return true
      return false
    },
    isDepartMentMisMatch(locationId, userLocationSet) {
      if (locationId === null) return false
      if (userLocationSet.size === 0) return false
      return !userLocationSet.has(locationId)
    },
    hasFilterCodeSchedules(code){
      if (!code) return true
      if (this.filterCodeSchedules.selectSet.size === 0) return true
      if (this.filterCodeSchedules.selectSet.size === this.filterCodeSchedules.selectValues.length) return true
      return this.filterCodeSchedules.selectSet.has(code.toLowerCase())
    },
    hasFilterLocations(departmentId){
      if (!departmentId) return true
      if (this.filterLocations.selectSet.size === 0) return true
      if (this.filterLocations.selectSet.size === this.filterLocations.selectValues.length) return true
      return this.filterLocations.selectSet.has(departmentId)
    },
    hasFilterSchedules(scheduleId){
      if (!scheduleId) return true
      if (this.filterSchedules.selectSet.size === 0) return true
      if (this.filterSchedules.selectSet.size === this.filterSchedules.selectValues.length) return true
      return this.filterSchedules.selectSet.has(scheduleId)
    },
    hasFilterCodeSets(codeScheduleId){
      // console.info(`=>hasFilterCodeSets:`, {codeScheduleId, filterCodeSets:this.filterCodeSets})
      if (!codeScheduleId) return true
      if (this.filterCodeSets.selectSet.size === 0) return true
      if (this.filterCodeSets.selectSet.size === this.filterCodeSets.selectValues.length) return true
      return this.filterCodeSets.selectSet.has(codeScheduleId)
    },
    hasFilterCodeTypes(codeType){
      // console.info(`=>hasFilterCodeTypes:`, {filterCodeTypes:this.filterCodeTypes})
      if (!codeType) return true
      if (this.filterCodeTypes.selectSet.size === 0) return true
      if (this.filterCodeTypes.selectSet.size === this.filterCodeTypes.selectValues.length) return true
      return this.filterCodeTypes.selectSet.has(codeType)
    },
    hasStreakOnly(isStreakpart){
      if (this.showSteaksOnly === false) return true
      return isStreakpart === 1
    },
    hasHolidayOnly(isHoliday){
      if (this.holidaysOnly === false) return true
      return isHoliday === 1
    },
    filterjson(){
      let rowWrap = document.createElement("div")
      rowWrap.className = 'rowsWrapper'
      rowWrap.innerHTML = ''


      const wWidth = window.innerWidth
      const calWith = Math.floor(wWidth * 0.9)
      const minDate = new Date(this.json.minDate)
      const maxDate = new Date(this.json.maxDate)
      const start = minDate.getTime()
      const range = (maxDate.getTime() - minDate.getTime())
      // console.info(`range:`, range)

      // let s = {}
      // for(let shift of this.json.shifts) {
      //   shift.start = new Date(shift.startTimeObj)
      //   shift.end = new Date(shift.endTimeObj)
      //   s[shift.shiftId] = shift
      // }
      const s = this.json.s
      const u = this.json.u
      // console.info(`this.filterCodeSchedules:`, this.filterCodeSchedules)
      // console.info(`this.filterCodeSets:`, this.filterCodeSets)
      // https://www.codegrepper.com/code-examples/javascript/frameworks/build/eslint-disable-next-line+no-case-declarations+disable+rule
      /* eslint-disable */
      // return

      let userList = []
      for(let user of this.json.users) {
        if (!this.hasUserMustHaveRole(user.rolesList)) continue
        if (!this.hasSearchMatch(user)) continue
        if (!this.hasQuotumMatch(user)) continue
        if (!this.hasPerformanceMatch(user)) continue
        if (!this.hasCoverageMatch(user)) continue
        if (!this.hasAtwUser(user)) continue
        if (this.errorMisMatch && !this.hasUserErrors(user)) continue

        if (!this.hasUserLocation(user)) continue



        // continue

        //user.assigned = this.json.shifts.filter(s => s.assignedToUserId === user.user_id)

        user.assignedFiltered = user.assigned.filter(s =>
          this.hasFilterCodeSchedules(s.codeCode) &&
          this.hasFilterLocations(s.departmentId) &&
          this.hasFilterSchedules(s.scheduleId) &&
          this.hasFilterCodeSets(s.codeScheduleId) &&
          this.hasFilterCodeTypes(s.type) &&
          this.hasStreakOnly(s.isStreakpart) &&
          this.hasHolidayOnly(s.isHoliday) &&
          this.hasAssignedPhaseMatch(s) &&
          this.hasLinkedShiftMatch(s) &&
          this.hasLocationMatch(s.departmentId, user.departmentsList)
        ) || []

        //user.availability = this.json.inputJSON.availability.filter(s => s.user_id === user.user_id)
        user.availabilityFiltered = this.showAvailability ? user.availability.filter(a => {
          return this.hasFilterCodeSets(s[a.shift_id].codeScheduleId) &&
          this.hasFilterCodeTypes(s[a.shift_id].type) &&
          this.hasFilterLocations(s[a.shift_id].departmentId) &&
          this.hasFilterSchedules(s[a.shift_id].scheduleId) &&
          this.hasFilterCodeSchedules(s[a.shift_id].codeCode) &&
          this.hasHolidayOnly(s[a.shift_id].isHoliday) &&
          this.hasStreakPart(a) &&
          this.hasLinkedShiftMatch(s[a.shift_id]) &&
          // (this.showSteaksOnly === false || (s[a.shift_id].isStreakpart == 1 && a.exempt_from_streak === 0))
          this.hasOpenAvailableMinMaxAnd(user, s[a.shift_id]) &&
          this.hasLocationMatch(s[a.shift_id].departmentId, new Set(user.departmentsList))
        }) : []

        // if(this.openAvailableOnly && user.availabilityFiltered.length <=0) continue

        /*
        if (user.user_id === 14358){
          console.info(`user.availabilityFiltered:`, user.availabilityFiltered.length)
        }*/

        if (!this.hasAvailableMatch(user)) continue
        if (!this.hasAssignedMatch(user)) continue
        if (!this.hasAssignedHolidaysMatch(user)) continue
        if (!this.hasStreakLowMaxAvailability(user)) continue


        userList.push(user)

      }
      // console.info(`userList:`, userList)

      userList = this.sortUserList(userList)
      this.userDetailsRows = []

      let count = 0
      for(let user of userList) {
        // continue
        // const minUserCount = this.paginationSize * this.curPage
        // const maxMaxUserCount = minUserCount + this.paginationSize -1
        // if (count < minUserCount) {
        //   count += 1
        //   continue
        // }
        // if (count > maxMaxUserCount) continue


        let createUserRow = document.createElement("div")
        createUserRow.className = 'userRow'



        let userDetailsWrap = document.createElement("div")
        userDetailsWrap.defaultclassNames = this.hasUserErrors(user) ? ['userDetails hasError']: ['userDetails']
        userDetailsWrap.className = userDetailsWrap.defaultclassNames.join(` `)
        // console.info(`user:`, user)
        // userDetailsWrap.innerText = `id:${user.user_id} q:${user.quotum}, p:${roundMaxDigits(user.postPerformance, 2)}, c:${roundMaxDigits(user.postCoverage,2)} ${user.email}`
        // userDetailsWrap.innerHTML = `<a href='https://waarneemapp.nl/companies/${this.companyId}/users/${user.company_user_id}/profile/${user.user_id}' target='_blank'>${user.user_id}-${user.email}-(q:${user.quotum}) -> ${user.assignedFiltered.length}</a>`
        userDetailsWrap.innerText = `${user.user_id} - ${user.email} - (q:${user.quotum}) -> ${user.assignedFiltered.length}`
        userDetailsWrap.user = user
        userDetailsWrap.onmouseenter = this.showUserDetails
        userDetailsWrap.onmouseout = this.hideDetails
        createUserRow.appendChild(userDetailsWrap)
        this.userDetailsRows.push(userDetailsWrap)
        
        if (this.enableAgenda) {
          let agendaUserRow = document.createElement("div")
          agendaUserRow.className = 'userAgendaBtn'
          agendaUserRow.innerHTML = "A"
          agendaUserRow.user = user
          agendaUserRow.onmouseenter = this.showUserAgenda
          agendaUserRow.onmouseout = this.hideDetails
          agendaUserRow.onclick = this.showUserAgenda
          createUserRow.appendChild(agendaUserRow)
        }

        let userCalGrid = document.createElement("div")
        userCalGrid.className = 'userCalGrid'
        createUserRow.appendChild(userCalGrid)

        let userCalLine = document.createElement("div")
        userCalLine.className = 'userCalLine'
        userCalGrid.appendChild(userCalLine)

        // let userShifts = this.json.shifts.filter(s => s.assignedToUserId === user.user_id)
        // console.info(`userShifts ${user.user_id}-${user.email}:`, userShifts.length)
        for(let userShift of user.assignedFiltered) {
          let userShiftDot = document.createElement("div")
          userShiftDot.user = user
          userShiftDot.shift = userShift
          let shiftStyles = ['userShift']

          let width = Math.min(2, (userShift.end.getTime() - userShift.start.getTime()) / range * calWith)
          if (!this.showBlocked && !this.showSlots) {
            width = width * 3
          }

          if (userShift.isNight === 1) shiftStyles.push('nightShift')
          if (userShift.isHoliday === 1) shiftStyles.push('holidayShift')
          if (userShift.type === 'reserve') shiftStyles.push('reserveShift')
          if (userShift.isStreakpart === 1) shiftStyles.push('streakShift')
          let errors = this.getShiftErrors(userShift)
          if (errors.length > 0) shiftStyles.push('shiftError')
          userShiftDot.className = shiftStyles.join(` `)
          userShiftDot.style.left = `${(userShift.start.getTime() - start) / range * 100}%`
          userShiftDot.style.width = `${width}px`
          userShiftDot.onmouseenter = this.showShiftDetails
          userShiftDot.onmouseout = this.hideDetails
          userCalGrid.appendChild(userShiftDot)
        }


        // let userAvailability = this.showAvailability ? this.json.inputJSON.availability.filter(a =>
        //   a.user_id === user.user_id &&
        //   this.filterCodeSets.selectSet.has(s[a.shift_id].codeScheduleId) &&
        //   this.filterCodeSchedules.selectSet.has(s[a.shift_id].codeCode.toLocaleLowerCase()) &&
        //   (this.showSteaksOnly === false || s[a.shift_id].isStreakpart === 1)
        // ) : []
        // console.info(`userAvailability ${user.user_id}-${user.email}:`, userAvailability.length)
        for(let userAvailable of user.availabilityFiltered) {
          let userAvailableDot = document.createElement("div")
          let availableShift = s[userAvailable.shift_id]
          // console.info(`availableShift:`, availableShift)
          userAvailableDot.user = s[userAvailable.shift_id].assignedToUserId ? u[s[userAvailable.shift_id].assignedToUserId] : user
          userAvailableDot.shift = availableShift
          let availableStyles = ['userAvailable']
          if (availableShift.isNight === 1) availableStyles.push('nightShift')
          if (availableShift.isHoliday === 1) availableStyles.push('holidayShift')
          if (availableShift.type === 'reserve') availableStyles.push('reserveShift')
          if (this.openAvailableOnly && availableShift.assignedToUserId === null) availableStyles.push('isOpen')
          userAvailableDot.className = availableStyles.join(` `)
          userAvailableDot.style.left = `${(availableShift.start.getTime() - start) / range * 100}%`
          userAvailableDot.style.width = `${Math.min(2, (availableShift.end.getTime() - availableShift.start.getTime()) / range * calWith) * 2}px`

          userAvailableDot.onmouseenter = this.showShiftDetails
          userAvailableDot.onmouseout = this.hideDetails

          userCalGrid.appendChild(userAvailableDot)
        }

        for(let blocked of user.blockedDays) {
          if (!this.showBlocked) continue
          let userblockedLine = document.createElement("div")
          userblockedLine.blocked = blocked
          userblockedLine.className = `blockedDay`
          userblockedLine.style.left = `${(blocked.start.getTime() - start) / range * 100}%`
          userblockedLine.style.width = `${Math.min(2, (blocked.end.getTime() - blocked.start.getTime()) / range * calWith) * 2}px`
          userCalGrid.appendChild(userblockedLine)
        }

        for(let slot of user.userSlots) {
          if (!this.showSlots) continue
          let userSlotLine = document.createElement("div")
          userSlotLine.slots = slot
          userSlotLine.className = `userSlot`
          userSlotLine.style.left = `${(slot.start.getTime() - start) / range * 100}%`
          userSlotLine.style.width = `${Math.min(2, (slot.end.getTime() - slot.start.getTime()) / range * calWith) * 2}px`
          userCalGrid.appendChild(userSlotLine)
        }



        rowWrap.appendChild(createUserRow)
        count +=1
        // console.info(`count:`, count)
        // if (count > 50) break
      }

      let hoverBoxWrap = document.createElement("div")
      hoverBoxWrap.className = 'hoverBox HideDiv'
      // hoverBoxWrap.innerText = 'DIT IS EEN TEST'
      this.hoverBox = hoverBoxWrap
      let mainWrap = document.getElementById('UserOverviewWrap')
      mainWrap.innerHTML = ''
      mainWrap.appendChild(rowWrap)
      mainWrap.appendChild(this.hoverBox)
      // this.jsonFiltered = this.json
    },


    getRolesFilterLabel(){
      if (this.filterRoles.selectSet.size === this.filterRoles.selectValues.length) return 'Roles'
      return [...this.filterRoles.selectSet].join(`, `)
    },
    getUserDepartmentsFilterLabel(){
      if (this.filterUseDepartments.selectSet.size === this.filterUseDepartments.selectValues.length) return 'User Departments'
      return `User Departments: ${[...this.filterUseDepartments.selectSet].join(`, `)}`
    },
    getLocationFilterLabel(){
      if (this.filterLocations.selectSet.size === this.filterLocations.selectValues.length) return 'Shift Locations'
      return `Shift Locations: ${[...this.filterLocations.selectSet].join(`, `)}`
    },
    getScheduleFilterLabel(){
      if (this.filterSchedules.selectSet.size === this.filterSchedules.selectValues.length) return 'Schedules'
      return [...this.filterSchedules.selectSet].join(`, `)
    },
    getCodeScheduleFilterLabel(){
      if (this.filterCodeSchedules.selectSet.size === this.filterCodeSchedules.selectValues.length) return 'CodeSchedules'
      return [...this.filterCodeSchedules.selectSet].join(`, `)
    },
    getCodeTypeFilterLabel(){
      let isActive = this.filterCodeTypes.selectValues.find(c => c.value === false)
      if (!isActive) return 'Type'
      let list = this.filterCodeTypes.selectValues.filter(c => c.value === true).map(c => c.name)
      return list.join(`, `)
    },
    getCodeSetsFilterLabel(){
      let isActive = this.filterCodeSets.selectValues.find(c => c.value === false)
      if (!isActive) return 'CodeSets'
      let list = this.filterCodeSets.selectValues.filter(c => c.value === true).map(c => c.name)
      return list.join(`, `)
    },
    getQuotumFilterLabel(){
      if (this.filterQuotum.min === this.filterQuotum.value[0] && this.filterQuotum.max === this.filterQuotum.value[1]) return 'Quotum'
      return `Quotum: ${this.filterQuotum.value[0]} - ${this.filterQuotum.value[1]}`
    },
    getPerformanceFilterLabel(){
      if (this.filterPerformance.min === this.filterPerformance.value[0] && this.filterPerformance.max === this.filterPerformance.value[1]) return 'Performance'
      return `Performance: ${this.filterPerformance.value[0]} - ${this.filterPerformance.value[1]}`
    },
    getCoverageFilterLabel(){
      if (this.filterCoverage.min === this.filterCoverage.value[0] && this.filterCoverage.max === this.filterCoverage.value[1]) return 'Coverage'
      return `Coverage: ${this.filterCoverage.value[0]} - ${this.filterCoverage.value[1]}`
    },
    getAssignedFilterLabel(){
      if (this.filterAssigned.min === this.filterAssigned.value[0] && this.filterAssigned.max === this.filterAssigned.value[1]) return 'Assigned'
      return `Assigned: ${this.filterAssigned.value[0]} - ${this.filterAssigned.value[1]}`
    },
    getAssignedPhaseFilterLabel(){
      if (this.filterAssignedPhase.min === this.filterAssignedPhase.value[0] && this.filterAssignedPhase.max === this.filterAssignedPhase.value[1]) return 'Assigned Phase'
      return `Phase: ${this.filterAssignedPhase.value[0]} - ${this.filterAssignedPhase.value[1]}`
    },
    getHolidaysFilterLabel(){
      if (this.filterHolidays.min === this.filterHolidays.value[0] && this.filterHolidays.max === this.filterHolidays.value[1]) return 'Holidays'
      return `Holidays: ${this.filterHolidays.value[0]} - ${this.filterHolidays.value[1]}`
    },
    getAvailableFilterLabel(){
      if (this.filterAvailable.min === this.filterAvailable.value[0] && this.filterAvailable.max === this.filterAvailable.value[1]) return 'Availablity'
      return `Availablity: ${this.filterAvailable.value[0]} - ${this.filterAvailable.value[1]}`
    },
    hasValue(val) {
      if (typeof(val) === "undefined") return false
      if (val === null) return false
      return true
    },
    showUserDetails(evt){
      // console.info(`evt:`, evt.srcElement.shift.shiftId)
      // console.info(`evt:`, `shift-${shift.shiftId}`)
      let user = evt.srcElement.user
      if (!user) this.hideDetails()
      this.showDetails(user, null, evt)
    },
    showShiftDetails(evt){
      // console.info(`evt:`, evt.srcElement.shift.shiftId)
      // console.info(`evt:`, `shift-${shift.shiftId}`)
      let shift = evt.srcElement.shift
      let user = evt.srcElement.user
      if (!shift) this.hideDetails()
      this.showDetails(user, shift, evt)
    },
    getShiftErrors(shift){
      if (!shift.errors) return []
      if (shift.errors.length <= 0) return []
      let list = []
      for(let err of shift.errors) {
        if (err.indexOf(`shift user department`) > -1 && err.indexOf(`mismatch`) > -1) continue
        list.push(err)
      }
      return list
    },
    errClass(statment){
      return statment ? 'hasError' : ''
    },
    limitClass(statment){
      return statment ? 'hasLimitReached' : ''
    },
    availableLostMaxHours(user) {
      if (!user.max) return ''

      let assigned = user.assigned
      let assignedSet = new Set(assigned.map(a => a.shiftId))
      let availability = user.availability.filter(a => !assignedSet.has(a.shift_id))
      if (availability.length <=0) return ''

      let cannotAvailable = []
      for(let available of availability) {
        if (user.postAssignedMinMaxHours + this.json.s[available.shift_id].minMaxHoursShift <= user.max) continue
        cannotAvailable.push(available)
      }
      if (cannotAvailable.length <=0) return ''
      // return ` (-${Math.round(100*(cannotAvailable.length / availability.length))}%)`
      return `    (-${cannotAvailable.length})`
    },
    availableLostMaxHours(user) {
      if (!user.max) return ''

      let assigned = user.assigned
      let assignedSet = new Set(assigned.map(a => a.shiftId))
      let availability = user.availability.filter(a => !assignedSet.has(a.shift_id))
      if (availability.length <=0) return ''

      let cannotAvailable = []
      for(let available of availability) {
        if (user.postAssignedMinMaxHours + this.json.s[available.shift_id].minMaxHoursShift <= user.max) continue
        cannotAvailable.push(available)
      }
      if (cannotAvailable.length <=0) return ''
      // return ` (-${Math.round(100*(cannotAvailable.length / availability.length))}%)`
      return `    (-${cannotAvailable.length})`
    },
    availableLostMaxHolidays(user) {
      if (!this.hasValue(user.max_holidays)) return ''

      let assigned = user.assigned
      let assignedSet = new Set(assigned.map(a => a.shiftId))
      let availability = user.availability.filter(a => !assignedSet.has(a.shift_id) && this.json.s[a.shift_id].isHoliday === 1)
      if (availability.length <=0) return ''

      if (user.minmax.holidays.count < user.max_holidays) return ''
      return `    (-${availability.length})`
    },
    availableLostMaxPerWeek(user) {
      if (!this.hasValue(user.max_per_week)) return ''

      let assigned = user.assigned
      let assignedSet = new Set(assigned.map(a => a.shiftId))
      let availability = user.availability.filter(a => !assignedSet.has(a.shift_id))
      if (availability.length <=0) return ''

      let cannotAvailable = []
      for(let available of availability) {
        let shift = this.json.s[available.shift_id]
        if (!user.minmax.week[shift.weekNr]) continue
        if (user.minmax.week[shift.weekNr].count < user.max_per_week) continue
        cannotAvailable.push(available)
      }
      if (cannotAvailable.length <=0) return ''
      // return ` (-${Math.round(100*(cannotAvailable.length / availability.length))}%)`
      return `    (-${cannotAvailable.length})`
    },
    availableLostMaxHoursPerWeek(user) {
      if (!this.hasValue(user.max_hours_per_week)) return ''

      let assigned = user.assigned
      let assignedSet = new Set(assigned.map(a => a.shiftId))
      let availability = user.availability.filter(a => !assignedSet.has(a.shift_id))
      if (availability.length <=0) return ''

      let cannotAvailable = []
      for(let available of availability) {
        let shift = this.json.s[available.shift_id]
        if (!user.minmax.week[shift.weekNr]) continue
        if (user.minmax.week[shift.weekNr].hours < user.max_hours_per_week) continue
        cannotAvailable.push(available)
      }
      if (cannotAvailable.length <=0) return ''
      // return ` (-${Math.round(100*(cannotAvailable.length / availability.length))}%)`
      return `    (-${cannotAvailable.length})`
    },
    availableLostMaxPerMonth(user) {
      if (!this.hasValue(user.max_per_month)) return ''

      let assigned = user.assigned
      let assignedSet = new Set(assigned.map(a => a.shiftId))
      let availability = user.availability.filter(a => !assignedSet.has(a.shift_id))
      if (availability.length <=0) return ''

      let cannotAvailable = []
      for(let available of availability) {
        let shift = this.json.s[available.shift_id]
        if (!user.minmax.month[shift.monthNr]) continue
        if (user.minmax.month[shift.monthNr].count < user.max_per_month) continue
        cannotAvailable.push(available)
      }
      if (cannotAvailable.length <=0) return ''
      // return ` (-${Math.round(100*(cannotAvailable.length / availability.length))}%)`
      return ` (-${cannotAvailable.length})`
    },
    availableLostMaxHoursPerMonth(user) {
      if (!this.hasValue(user.max_hours_per_month)) return ''

      let assigned = user.assigned
      let assignedSet = new Set(assigned.map(a => a.shiftId))
      let availability = user.availability.filter(a => !assignedSet.has(a.shift_id))
      if (availability.length <=0) return ''

      let cannotAvailable = []
      for(let available of availability) {
        let shift = this.json.s[available.shift_id]
        if (!user.minmax.month[shift.monthNr]) continue
        if (user.minmax.month[shift.monthNr].hours < user.max_hours_per_month) continue
        cannotAvailable.push(available)
      }
      if (cannotAvailable.length <=0) return ''
      // return ` (-${Math.round(100*(cannotAvailable.length / availability.length))}%)`
      return ` (-${cannotAvailable.length})`
    },
    showDetails(user, shift, evt){

      let t = []
      if (shift) {
        t.push(`${shift.date} ${shift.startTime}-${shift.endTime}`)
        t.push(`${shift.scheduleName}`)
        t.push(`${shift.codeCode} - ${shift.codeName} - ${shift.type}`)
        t.push(`<strong>ShiftId</strong>: ${shift.shiftId}`)
        t.push(`<strong>VancacyId</strong>: ${shift.vacancyId}`)
        t.push(`<strong>DepartmentId</strong>:`, shift.departmentId ? shift.departmentId : 'NULL')
        if (shift.isHoliday) t.push(`<strong>Holiday</strong>:`, shift.holidayName)

      }
      t.push(`<strong>UserId</strong>: ${user.user_id}`)
      t.push(`<strong>CompanyUserId</strong>: ${user.company_user_id}`)
      t.push(`<strong>Email</strong>: ${user.email}`)
      t.push(`<strong>Departments</strong>: ${user.departmentsList.join(`,`)}`)
      t.push(`<strong>Roles</strong>: ${user.rolesList.join(`, `)}`)
      t.push(`<strong>Quotum</strong>: ${user.quotum}`)
      t.push(`<strong>Performance</strong>: ${ roundMaxDigits(user.postPerformance, 2)}`)
      const lostCoverage = this.availableLostMaxHours(user)
      t.push(`<strong class='${this.limitClass(lostCoverage !== '')}'>PostCoverage</strong>: ${ roundMaxDigits(user.postCoverage, 2)} ${lostCoverage}`)

      const userAvailability = this.json.inputJSON.availability.filter(a => a.user_id === user.user_id)
      const availableSet = new Set(userAvailability.map(a => a.shift_id))
      const userAssigned = this.json.shifts.filter(s => s.assignedToUserId === user.user_id)

      t.push(`<br>Main:`)
      const userMinMaxPref = user.minMaxPref // this.json.inputJSON.min_max_hours.find(u => u.user_id === user.user_id) || {}
      if (this.hasValue(userMinMaxPref.max_hours)) t.push(`<strong class='${this.errClass(parseInt(userMinMaxPref.max_hours) <=0)} ${this.limitClass(lostCoverage !== '')}'>min-max hours</strong>: ${userMinMaxPref.min_hours}- ${userMinMaxPref.max_hours} ${lostCoverage}`)
      const lostHolidays = this.availableLostMaxHolidays(user)
      if (this.hasValue(userMinMaxPref.min_holidays)) t.push(`<strong class='${this.limitClass(lostHolidays !== '')}'>min-max holidays</strong>: ${userMinMaxPref.min_holidays}-${userMinMaxPref.max_holidays} ${lostHolidays}`)

      let userPref = this.json.inputJSON.users.find(u => u.user_id === user.user_id) || {}
      const lostMaxPerweek = this.availableLostMaxPerWeek(user)
      if (this.hasValue(userPref.max_per_week)) t.push(`<strong class='${this.errClass(userPref.max_per_week <= 0)}}'>min-max per week</strong>: ${userPref.min_per_week}-${userPref.max_per_week} ${lostMaxPerweek}`)
      const lostMaxHoursPerweek = this.availableLostMaxHoursPerWeek(user)
      if (this.hasValue(userPref.max_hours_per_week)) t.push(`<strong class='${this.errClass(userPref.max_hours_per_week <= 0)}}'>min-max hours per week</strong>: ${userPref.min_hours_per_week}-${userPref.max_hours_per_week} ${lostMaxHoursPerweek}`)

      const lostMaxPerMonth = this.availableLostMaxPerMonth(user)
      if (this.hasValue(userPref.max_per_month)) t.push(`<strong class='${this.errClass(userPref.max_per_month <= 0)}'>min-max per month</strong>: ${userPref.min_per_month}-${userPref.max_per_month} ${lostMaxPerMonth}`)
      const lostMaxHoursPerMonth = this.availableLostMaxHoursPerMonth(user)
      if (this.hasValue(userPref.max_hours_per_month)) t.push(`<strong class='${this.errClass(userPref.max_hours_per_month <= 0)}'>min-max hours per month</strong>: ${userPref.min_hours_per_month}-${userPref.max_hours_per_month} ${lostMaxHoursPerMonth}`)

      t.push(`<br><strong>total Available</strong>: ${userAvailability.length || 0}`)
      t.push(`<strong>total Assigned</strong>: ${userAssigned.length || 0} (${Math.ceil(user.postAssignedMinMaxHours)} hours)`)

      let userCodeSchedulePreferences = userMinMaxPref.code_preferences || []
      if (userCodeSchedulePreferences.find(c => typeof(c.code_schedule_ids) !== "undefined")) t.push(`<br>CodeSets:`)
      for(let code_preference of userCodeSchedulePreferences){
        if (typeof(code_preference.code_schedule_ids) === "undefined") continue
        let availableShifts = this.json.shifts.filter(s => availableSet.has(s.shiftId) && new Set(code_preference.code_schedule_ids).has(s.codeScheduleId))
        let AssignedShifts = userAssigned.filter(s => new Set(code_preference.code_schedule_ids).has(s.codeScheduleId))

        let hasMaxIssue = this.hasValue(code_preference.max_shifts) && parseInt(code_preference.max_shifts) <= 0 && availableShifts.length > 0
        let hasLimitReached = this.hasValue(code_preference.max_shifts) && AssignedShifts.length >= code_preference.max_shifts
        let list = [`<strong class='${this.errClass(hasMaxIssue)} ${this.limitClass(hasLimitReached)}'>${code_preference.name}</strong>:`]

        if (this.hasValue(code_preference.min_shifts)) list.push(`min shifts: ${code_preference.min_shifts}`)
        if (this.hasValue(code_preference.max_shifts)) list.push(`max shifts: ${code_preference.max_shifts}`)
        if (this.hasValue(code_preference.ignore_available) && code_preference.ignore_available === 1) list.push(`ignore_available: ${code_preference.ignore_available}`)


        list.push(` -> ${availableShifts.length} / ${AssignedShifts.length}`)
        t.push(list.join(` - `))
      }

      if (userCodeSchedulePreferences.find(c => typeof(c.code_schedule_ids) === "undefined")) t.push(`<br>Codes:`)
      for(let code_preference of userCodeSchedulePreferences){
        if (typeof(code_preference.code_schedule_ids) !== "undefined") continue
        // console.info(`code_preference:`, code_preference)
        let findCode = this.json.codeSchedules.find(c => c.codeScheduleId === code_preference.code_schedule_id) || {}
        // console.info(`findCode:`, findCode)

        let availableShifts = this.json.shifts.filter(s => availableSet.has(s.shiftId) && code_preference.code_schedule_id === s.codeScheduleId)
        let AssignedShifts = userAssigned.filter(s => code_preference.code_schedule_id === s.codeScheduleId)
        let hasMaxIssue = this.hasValue(code_preference.max_shifts) && parseInt(code_preference.max_shifts) <= 0 && availableShifts.length > 0
        let hasLimitReached = this.hasValue(code_preference.max_shifts) && AssignedShifts.length >= code_preference.max_shifts

        let list = [`<strong class='${this.errClass(hasMaxIssue)} ${this.limitClass(hasLimitReached)}'>${findCode.codeCode}</strong>:`]
        if (this.hasValue(code_preference.min_shifts)) list.push(`min shifts: ${code_preference.min_shifts}`)
        if (this.hasValue(code_preference.max_shifts)) list.push(`max shifts: ${code_preference.max_shifts}`)
        if (this.hasValue(code_preference.ignore_available) && code_preference.ignore_available === 1) list.push(`ignore_available: ${code_preference.ignore_available}`)


        list.push(` -> ${availableShifts.length} / ${AssignedShifts.length}`)
        t.push(list.join(` - `))
      }

      if (userMinMaxPref.schedule_preferences) {
        t.push(`<br>Schedules:`)
        for(let schedule_preference of userMinMaxPref.schedule_preferences){
          // console.info(`schedule_preference:`, schedule_preference)
          let schedule = this.json.inputJSON.schedules.find(s => s.id === schedule_preference.schedule_id) || {}
          let availableShifts = this.json.shifts.filter(s => availableSet.has(s.shiftId) && schedule_preference.schedule_id === s.scheduleId)
          let AssignedShifts = userAssigned.filter(s => schedule_preference.schedule_id === s.scheduleId)
          let hasMaxIssueShift = this.hasValue(schedule_preference.max_shifts) && parseInt(schedule_preference.max_shifts) <= 0 && availableShifts.length > 0
          let hasMaxIssueHours = this.hasValue(schedule_preference.max_hours) && parseInt(schedule_preference.max_hours) <= 0 && availableShifts.length > 0
          let hasLimitReachedShift = this.hasValue(schedule_preference.max_shifts) && AssignedShifts.length >= schedule_preference.max_shifts
          let hasLimitReachedHours = this.hasValue(schedule_preference.max_hours) && user.minmax.schedule[schedule_preference.schedule_id].hours >= user.minmax.schedule[schedule_preference.schedule_id].userPref_max_hours

          // console.info(`schedule:`, schedule)
          let list = [`<strong class='${this.errClass(hasMaxIssueShift || hasMaxIssueHours)} ${this.limitClass(hasLimitReachedShift || hasLimitReachedHours)}'>${schedule.id}-${schedule.name}</strong>:`]
          if (this.hasValue(schedule_preference.min_shifts)) list.push(`min shifts: ${schedule_preference.min_shifts}`)
          if (this.hasValue(schedule_preference.max_shifts)) list.push(`max shifts: ${schedule_preference.max_shifts}`)
          if (this.hasValue(schedule_preference.min_hours)) list.push(`min hours: ${schedule_preference.min_hours}`)
          if (this.hasValue(schedule_preference.max_hours)) list.push(`max hours: ${schedule_preference.max_hours}`)


          list.push(` -> ${availableShifts.length} / ${AssignedShifts.length}`)
          t.push(list.join(` - `))
        }
      }

      t.push(`<br>Schedules2:`)
      for(let schedule of this.json.inputJSON.schedules){

        let availableShifts = this.json.shifts.filter(s => availableSet.has(s.shiftId) && schedule.id === s.scheduleId)
        let AssignedShifts = userAssigned.filter(s => schedule.id === s.scheduleId)

        let list = [`<strong>${schedule.id}-${schedule.name}</strong>:`]

        list.push(` -> ${availableShifts.length} / ${AssignedShifts.length}`)
        t.push(list.join(` - `))
      }

      // console.info(`shift:`, shift)
      let errors = shift ? this.getShiftErrors(shift) : []
      if (errors.length > 0) {
        t.push(`<br>Shift Errors:`)
        if (errors.length > 0) t.push(`errors:`, errors.join(`, `))
      }

      if (this.hasUserErrors(user)) {
        t.push(`<br>Errors:`)
        // t.push(`<div class='userErrors>`)
        // console.info(`user:`, user)
        if (user.errMax.length > 0) t.push(`ErrMax:`, user.errMax.join(`, `))
        // if (user.errDepartment.length > 0) t.push(`  ErrDepartment: ${user.errDepartment.join(`, `)}`)
        if (user.errCodeSetMax.length > 0) t.push(`  errCodeSetMax: ${user.errCodeSetMax.join(`, `)}`)
        if (user.errCodeMax.length > 0) t.push(`  errCodeMax:  ${user.errCodeMax.join(`, `)}`)
        if (user.errMaxPerWeek.length > 0) t.push(`  errMaxPerWeek: ${user.errMaxPerWeek.join(`, `)}`)
        if (user.errMaxPerMonth.length > 0) t.push(`  errMaxPerMonth: ${user.errMaxPerMonth.join(`, `)}`)
        if (user.errMaxHolidays.length > 0) t.push(`  errCodeSetMax: ${user.errMaxHolidays.join(`, `)}`)
        if (user.errTimeBetweenShifts.length > 0) t.push(`  errCodeSetMax: ${user.errTimeBetweenShifts.join(`, `)}`)
        if (user.errOther.length > 0) t.push(`  errCodeSetMax: ${user.errOther.join(`, `)}`)
        // t.push(`</div>`)
        // console.info(`t:`, t)
      }



      this.hoverBox.innerHTML = t.join(`<br>`)
      this.hoverBox.className = 'hoverBox'
      let OffsetTop = this.calcPageOffsetPosY(evt.srcElement)
      let OffsetLeft = this.calcPageOffsetPosX(evt.srcElement)
      if (OffsetLeft > window.innerWidth * 0.6) {
        OffsetLeft = Math.max(0, OffsetLeft - 500)
      }
      this.hoverBox.style.top   = `${OffsetTop + 20}px`
      this.hoverBox.style.left   = `${OffsetLeft - 20}px`

      this.generateTableData(user, shift)
    },
    generateTableData(user){
      this.tableUserAssigned = []
      this.tableUserAvailable = []
      this.tableUserCodeOverview = []
      this.tableUser = user
      if (!user) return
      if (!this.showTables) return



      let assignedList = []
      for(let assign of user.assignedFiltered){
        assignedList.push({
          ...assign,
          ...this.json.s[assign.shift_id]
        })
      }
      this.tableUserAssigned = assignedList
      // console.info(`this.tableUserAssigned:`, this.tableUserAssigned)

      let availableList = []
      for(let available of user.availabilityFiltered){
        const assginedUser = this.json.s[available.shift_id].assignedToUserId ? this.json.u[this.json.s[available.shift_id].assignedToUserId] : null
        let assignedUserDetails = {
          assignedUserPostCoverage: assginedUser ? Math.round(100* assginedUser.postCoverage)/100 : '',
          assignedUserPostPerformance: assginedUser ? Math.round(100*assginedUser.postPerformance)/100 : '',
          assignedUserMax: assginedUser ? assginedUser.max : ''
        }

        availableList.push({
          ...available,
          ...this.json.s[available.shift_id],
          ...assignedUserDetails
        })
      }
      this.tableUserAvailable = availableList
      console.info(`this.tableUserAvailable:`, this.tableUserAvailable)
      // console.info(`this.json.u[6915]:`, this.json.u[6915])

      let codeScheduleOverview = []
      for(let codeSchedule of this.json.inputJSON.code_schedules) {
        let uA = {
          count: this.json.shifts.filter(s => s.code_schedule_id === codeSchedule.code_schedule_id).length,
          assigned: this.tableUserAssigned.filter(a => a.codeScheduleId === codeSchedule.code_schedule_id).length,
          availabe: this.tableUserAvailable.filter(a => a.codeScheduleId === codeSchedule.code_schedule_id).length,
          codeSets: this.getCodeScheduleCodeSetList(codeSchedule.code_schedule_id).map(c => c.name).join(`,`)
        }
        if (uA.assigned <=0 && uA.availabe <=0) continue
        codeScheduleOverview.push({
          ...uA,
          ...codeSchedule
        })
      }
      this.tableUserCodeOverview = codeScheduleOverview
      console.info(`this.tableUserCodeOverview:`, this.tableUserCodeOverview)

      // console.info(`user:`, user)
      // console.info(`shift:`, shift)
    },
    getCodeScheduleCodeSetList(codeScheduleId) {
      if (!codeScheduleId) return []
      return this.json.inputJSON.code_sets.filter(c => new Set(c.code_schedule_ids).has(codeScheduleId))
    },
    hideDetails(){
      if (this.hoverBoxAutoCloseDisabled) return
      this.hoverBox.className = 'hoverBox HideDiv'
      this.hoverBox.innerHTML = ''
    },
    calcPageOffsetPosY(obj) {
      if (obj.offsetParent) {
        return obj.offsetTop + this.calcPageOffsetPosY(obj.offsetParent);
      } else if (obj.y) {
        return obj.y + this.calcPageOffsetPosY(obj.offsetParent);
      } else {
        return obj.offsetTop;
      }
    },
    calcPageOffsetPosX(obj) {
      if (obj.offsetParent) {
        return obj.offsetLeft + this.calcPageOffsetPosX(obj.offsetParent);
      } else if (obj.x) {
        return obj.x + this.calcPageOffsetPosX(obj.offsetParent);
      } else {
        return obj.offsetLeft;
      }
    },
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;

      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }

      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },

    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },

    /* eslint-disable-next-line */
    dateThAttrs(column) {
      return null
    },

    /* eslint-disable-next-line */
    columnTdAttrs(row, column) {
      return null
    },

    update(){
      this.filterjson()
      // console.info(`this.tableUser:`, this.tableUser)
      this.generateTableData(this.tableUser)
    },

    showUserAgenda(evt) {
      let user = evt.srcElement.user
      this.agendaUser = user
      if (!user) this.hideDetails()

      // console.info(`showUserAgenda:`, this)
      this.showAgenda = true

      let eventList = []

      let userBlocked = this.json.inputJSON.blocked_days.find(u => u.user_id === user.user_id)
      // console.info(`userBlocked:`, userBlocked)
      if (userBlocked && userBlocked.blocks.length > 0) {
        for(let block of userBlocked.blocks) {
          let start = new Date(block)
          let end = new Date(start)
          end.setDate(start.getDate() +1)
          if (!myDates.hasOverLap(this.agendaPeriod.from, this.agendaPeriod.till, start, end)) continue
          eventList.push({
            name: 'blocked',
            bcolor: 'red',
            color: '',
            from: new Date(start),
            till: new Date(end),
            type: 'blocked'
          })
        }
      }

      let userReservedSlots = this.json.inputJSON.reserved_slots.find(u => u.user_id === user.user_id)
      // console.info(`userReservedSlots:`, userReservedSlots)
      if (userReservedSlots && userReservedSlots.slots.length > 0) {
        for(let slot of userReservedSlots.slots) {
          let start = new Date(slot.start_datetime)
          let end = new Date(slot.end_datetime)
          if (!myDates.hasOverLap(this.agendaPeriod.from, this.agendaPeriod.till, start, end)) continue
          eventList.push({
            name: slot.type,
            bcolor: 'orange',
            color: '',
            from: new Date(start),
            till: new Date(end),
            type: slot.type
          })
        }
      }


      let preference_blocks = this.json.inputJSON.preference_blocks || []
      let userPreferenceBlocks = preference_blocks.find(u => u.user_id === user.user_id)
      // console.info(`userPreferenceBlocks:`, userPreferenceBlocks)
      if (userPreferenceBlocks && userPreferenceBlocks.blocks.length > 0) {
        for(let block of userPreferenceBlocks.blocks) {
          let start = new Date(block.start_datetime)
          let end = new Date(block.end_datetime)
          if (!myDates.hasOverLap(this.agendaPeriod.from, this.agendaPeriod.till, start, end)) continue
          eventList.push({
            name: block.type,
            bcolor: block.type  === 'unavailable' ? '#F0B2D3' : 'lightgreen',
            color: '',
            from: new Date(start),
            till: new Date(end),
            type: block.type
          })
        }
      }
      this.agendaEvents = eventList
      this.agendaUser = user
      // console.info(`this.agendaEvents:`, this.agendaEvents)

      for(let shift of user.assignedFiltered) {
        if (!myDates.hasOverLap(this.agendaPeriod.from, this.agendaPeriod.till, shift.start, shift.end)) continue
        eventList.push({
          name: `${shift.codeCode}`,
          bcolor: this.agendaShiftBColor(shift),
          color: this.agendaShiftColor(shift),
          from: new Date(shift.start),
          till: new Date(shift.end),
          type: 'shift'
        })
      }
      try {
        this.$refs.agenda.calcDays()
      } catch(e) {
        
      }
      this.highlightSelectedUser()

    },
    hideUserAgenda() {
      this.showAgenda = false
      this.agendaUser = null
    },
    agendaShiftBColor(shift) {
      let starthour = shift.start.getHours()
      if (starthour >= 7 && starthour < 10) return '#FFC0CB'
      if (starthour >= 10 && starthour < 14) return '#1E90FF'
      if (starthour >= 14 && starthour < 17) return '#DAA520'
      if (starthour >= 17 && starthour < 20) return '#FF4500'
      if (starthour >= 20 && starthour < 23) return '#800080'
      if (starthour >= 23 || starthour < 4) return '#013220'
      
      return 'lightblue'
    },
    agendaShiftColor(shift) {
      let starthour = shift.start.getHours()
      if (starthour >= 7 && starthour < 10) return '#000'
      if (starthour >= 10 && starthour < 14) return '#000'
      if (starthour >= 14 && starthour < 17) return '#000'
      if (starthour >= 17 && starthour < 20) return '#000'
      if (starthour >= 20 && starthour < 23) return '#000'
      if (starthour >= 23 || starthour < 4) return '#FFF'
      
      return '#000'
    },
    highlightSelectedUser(){
      for(let userDetailsRow of this.userDetailsRows) {
        let classes = [...userDetailsRow.defaultclassNames]
        if (userDetailsRow.user.user_id === this.agendaUser.user_id) {
          classes.push('selected')  
        }
        // console.info(`classes:`, classes)
        userDetailsRow.className = classes.join(` `)
      }
    }


  }
};
</script>

<style>
  #UserOverviewWrap{
    float:left;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    margin-top: 80px;
    /* padding-bottom: 1500px; */
    background-color: #f4f6fb;
    z-index: 900;
  }
  .rowsWrapper{
    float:left;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }
  .userAgendaBtn{
    float: left;
    cursor: pointer;
    margin-left:5px;
    margin-right:5px;
  }
  .userRow{
    float: left;
    width: 100%;
    line-height: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    margin-bottom: 2px;
    /* border-bottom: 1px solid #000; */
  }
  .userDetails{
    float: left;
    width: 20%;
    text-align: right;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    /* padding-left: 1%;     */
    padding-right: 5px;
    font-size: 12px;
    /* background-color: red; */
    overflow: hidden;
    line-height: 20px;
    height: 20px;
    /* border-bottom: 1px solid #000; */
  }

  .userDetails.hasLimitReached{
    color: orange;
  }
  .userDetails.hasError{
    color: red;
  }
  .userDetails.selected{
    text-decoration: underline;
  }


  .userCalGrid{
    float:left;
    position: relative;
    width: 69%;
    line-height: 20px;
    height: 20px;
    /* background-color: green; */
    white-space: nowrap;
    padding: 0;
    margin: 0;
  }

  .userCalLine{
    float:left;
    position: absolute;
    top:15px;
    left:0;
    width: 100%;
    height: 1px;
    background-color: lightgrey;
  }

  .userAvailable{
    float:left;
    position: absolute;
    top:16px;
    height: 3px;
    background-color: lightskyblue;
  }
  .blockedDay{
    float:left;
    position: absolute;
    top:11px;
    height: 3px;
    background-color: red;
    /* border: 1px solid purple; */
  }
  .userSlot{
    float:left;
    position: absolute;
    top:11px;
    height: 3px;
    background-color: red;
    /* border: 1px solid purple; */
  }

  .userAvailable.isOpen{
    background-color: red;
    border:1px solid orange;
    height: 5px;
  }

  /* .userAvailable.nightShift{
    background-color: lightcoral;
  }
  .userAvailable.reserveShift{
    background-color: lightblue;
  }
  .userAvailable.streakShift{
    background-color: orange;
  }
  .userAvailable.holidayShift{
    background-color: green;
  } */


  .userShift{
    float:left;
    position: absolute;
    top:7px;
    height: 8px;
    background-color: grey;
    border: 1px solid grey;
  }
  .userShift:hover{
    border: 1px solid #000;
    cursor: pointer;
  }
  .userShift.nightShift{
    background-color: blue;
  }
  .userShift.reserveShift{
    background-color: black;
  }
  .userShift.streakShift{
    background-color: orange;
  }
  .userShift.holidayShift{
    background-color: red;
  }
  .userShift.shiftError{
    background-color: red;
    border: 1px solid yellow;
  }

  .hoverBox{
    float:left;
    position: absolute;
    left: 50px;
    width: auto;
    text-align: center;
    background-color:#FFF;
    border: 1px solid #aeaeae;
    padding: 30px;
    font-size: 12px;
    line-height: 14px;
    z-index: 999;
  }
  .hoverBox.HideDiv{
    display: none;
  }

  .hoverBox .userErrors{
    float: left;
    display: inline;
    color: red;
  }

  .hasLimitReached{
    color: orange;
  }
  .hasLimitReached.hasError,
  .hasError{
    color: red;
  }

  .addExtraBottomMargin{
    position: relative;
    width: 100%;
    height: 55000px;
    /* background-color: red; */
  }

</style>